import {
  MachineDocumentListResponse,
  SerialMachineData,
} from '@mygrimme/types';

export enum loadingStatus {
  NOT_LOADED = 'not loaded',
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error',
}

export type LocalMachine = SerialMachineData & {
  documents?: Record<string, MachineDocumentListResponse>;
};
