import Butter from 'buttercms';
import { environment } from '../../../environments/environment';

export const getPage = async (
  locale: string,
  preview: boolean,
  pageSlug: string,
  pageType = '*',
) => {
  const butterToken = environment.cmsAuthToken || '';
  const butter = Butter(butterToken);

  const butterParams = {
    locale: locale,
    preview: preview ? 1 : 0,
  };

  const page = await butter.page.retrieve(pageType, pageSlug, butterParams);

  return page.data.data;
};
