import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { loadingStatus } from './utils';
import { userGridApi } from '../utils/rest-apis/grid/api';
import { UserMachineDto } from '~/gen/grid';
import { NotificationMessage, notificationsActions } from '@grimme/components';

export const MACHINES_FEATURE_KEY = 'machinesV2';
export interface MachinesState extends EntityState<UserMachineDto> {
  error?: string;
  isAddingMachine: boolean;
  loadingStatus: loadingStatus;
}

export const machinesAdapter = createEntityAdapter<UserMachineDto>({
  selectId: (entity) => entity.serialNumber as string,
});

export const fetchMachines = createAsyncThunk(
  'machinesV2/fetch',
  async (args: { accessToken: string }) => {
    const response =
      await userGridApi.machinesApi.currentUserMachinesControllerGetAllMachines(
        {
          headers: {
            Authorization: `Bearer ${args.accessToken}`,
          },
        },
      );

    return response.data;
  },
);

export const addMachine = createAsyncThunk(
  'machinesV2/addMachine',
  async (
    args: {
      accessToken: string;
      payload: {
        serialNumber: string;
      };
    },
    { dispatch },
  ) => {
    try {
      const response =
        await userGridApi.machinesApi.currentUserMachinesControllerCreateOwnership(
          args.payload.serialNumber,
          {
            headers: {
              Authorization: `Bearer ${args.accessToken}`,
            },
          },
        );

      dispatch(
        notificationsActions.addSuccessNotification({
          message: 'myGRIMME_core_machine_add_success' as NotificationMessage,
        }),
      );

      return response.data;
    } catch (err) {
      dispatch(
        notificationsActions.addErrorNotification({
          message: 'myGRIMME_core_machine_add_failure' as NotificationMessage,
        }),
      );
    }
  },
);

export const initialMachinesState: MachinesState =
  machinesAdapter.getInitialState({
    error: undefined,
    isAddingMachine: false,
    loadingStatus: loadingStatus.NOT_LOADED,
  });

export const machinesSlice = createSlice({
  name: MACHINES_FEATURE_KEY,
  initialState: initialMachinesState,
  reducers: {
    add: machinesAdapter.addOne,
    remove: machinesAdapter.removeOne,
    resetEditMachineNameStatus: (state) => {
      state.loadingStatus = loadingStatus.NOT_LOADED;
    },
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMachines.pending, (state: MachinesState) => {
        state.loadingStatus = loadingStatus.LOADING;
      })
      .addCase(
        fetchMachines.fulfilled,
        (
          state: MachinesState,
          action: PayloadAction<UserMachineDto[], string>,
        ) => {
          machinesAdapter.upsertMany(state, action.payload);
          state.loadingStatus = loadingStatus.LOADED;
        },
      )
      .addCase(fetchMachines.rejected, (state, action) => {
        state.loadingStatus = loadingStatus.ERROR;
        state.error = action.error.message;
      })
      .addCase(addMachine.pending, (state) => {
        state.isAddingMachine = true;
      })
      .addCase(addMachine.fulfilled, (state, action) => {
        state.isAddingMachine = false;

        if (!action.payload) return;

        machinesAdapter.addOne(state, action.payload);
      })
      .addCase(addMachine.rejected, (state) => {
        state.isAddingMachine = false;
      });
  },
});

export const machinesReducer = machinesSlice.reducer;

export const machinesActions = machinesSlice.actions;

const { selectAll, selectEntities } = machinesAdapter.getSelectors();

export const getMachinesState = (rootState: {
  [MACHINES_FEATURE_KEY]: MachinesState;
}): MachinesState => rootState[MACHINES_FEATURE_KEY];

export const selectAllMachines = createSelector(getMachinesState, selectAll);

export const selectMachinesEntities = createSelector(
  getMachinesState,
  selectEntities,
);

export const selectMachinesEntityBySerial = (serial: string) =>
  createSelector(selectMachinesEntities, (state) => state[serial]);

export const selectIsLoadingMachines = createSelector(
  getMachinesState,
  (state) => state.loadingStatus === loadingStatus.LOADING,
);

export const selectAreMachinesLoaded = createSelector(
  getMachinesState,
  (state) => state.loadingStatus === loadingStatus.LOADED,
);

export const selectMachineLoadingState = createSelector(
  getMachinesState,
  (state) => state.loadingStatus,
);

export const isMachinePresent = (serial: string) =>
  createSelector(getMachinesState, (state) =>
    state.ids.includes(serial.toString()),
  );

export const areMachinesDifferentFromProfile = (
  machineIdsFromProfile: EntityId[],
) =>
  createSelector(getMachinesState, (state) =>
    machineIdsFromProfile.some((mId) => !state.ids.includes(mId)),
  );

export const selectNotLoadedSerials = (serials: Array<string>) =>
  createSelector(getMachinesState, (state) =>
    serials
      .filter((mId) => !state.ids.includes(mId))
      .map((id) => id.toString()),
  );

export const selectMachineFetchError = createSelector(
  getMachinesState,
  (state) => state.error,
);

export const selectIsAddingMachine = createSelector(
  getMachinesState,
  (state) => state.isAddingMachine,
);
