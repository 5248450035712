import {
  faCaretRight,
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Checkbox, FormGroup, Typography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from '../../progress-bar';
import {
  StyledCheckFilled,
  StyledCrossFilled,
  StyledGrid,
  classes,
} from './styles';

export interface StatusProps {
  statuses: boolean[];
  truthyStatusCount: number;
}

export const Status = (props: StatusProps) => {
  const { statuses, truthyStatusCount } = props;
  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState<boolean>(false);
  const handleChange = (expanded: boolean) => () => {
    setExpanded(expanded);
  };

  return (
    <StyledGrid
      container
      item
      justifyContent="flex-start"
      data-testid="status-component"
    >
      <MuiAccordion
        square
        expanded={expanded}
        onChange={handleChange(!expanded)}
        classes={{
          root: classes.root,
          expanded: classes.expanded,
        }}
        disableGutters
      >
        <MuiAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          classes={{
            root: classes.root2,
            content: classes.content,
            expanded: classes.expanded2,
          }}
        >
          <Box className={classes.arrowIconBox}>
            <FontAwesomeIcon
              icon={faCaretRight}
              className={`${classes.arrowIconDefault} ${
                expanded && classes.arrowIconDown
              }`}
            />
          </Box>
          <Typography variant="h5" className={classes.accountStatusTitle}>
            {t('myGRIMME_core_status_title', 'Accountstatus')}
            <span
              className={classes.accountStatusCount}
            >{`${truthyStatusCount}/3`}</span>
          </Typography>
        </MuiAccordionSummary>
        <MuiAccordionDetails
          classes={{
            root: classes.root3,
          }}
        >
          <FormGroup>
            <FormControlLabel
              className={classes.accountStatusDetails}
              control={
                <Checkbox
                  icon={
                    <StyledCrossFilled
                      icon={faCircleXmark}
                      data-testid="not-checked-1"
                    />
                  }
                  checkedIcon={
                    <StyledCheckFilled
                      icon={faCircleCheck}
                      data-testid="checked-1"
                    />
                  }
                  name="checkedH"
                  checked={statuses[0]}
                />
              }
              label={t(
                'myGRIMME_core_is_address_completed',
                'Adresse vollständig ausgefüllt',
              )}
            />
            <FormControlLabel
              className={classes.accountStatusDetails}
              control={
                <Checkbox
                  icon={
                    <StyledCrossFilled
                      icon={faCircleXmark}
                      data-testid="not-checked-2"
                    />
                  }
                  checkedIcon={
                    <StyledCheckFilled
                      icon={faCircleCheck}
                      data-testid="checked-2"
                    />
                  }
                  name="checkedH"
                  checked={statuses[1]}
                />
              }
              label={t(
                'myGRIMME_core_is_email_verified',
                'E-Mail Adresse bestätigt',
              )}
            />
            <FormControlLabel
              className={classes.accountStatusDetails}
              control={
                <Checkbox
                  icon={
                    <StyledCrossFilled
                      icon={faCircleXmark}
                      data-testid="not-checked-3"
                    />
                  }
                  checkedIcon={
                    <StyledCheckFilled
                      icon={faCircleCheck}
                      data-testid="checked-3"
                    />
                  }
                  name="checkedH"
                  checked={statuses[2]}
                />
              }
              label={t(
                'myGRIMME_core_is_account_verified',
                'Account verifiziert',
              )}
            />
          </FormGroup>
        </MuiAccordionDetails>
      </MuiAccordion>
      <ProgressBar truthyStatusCount={props.truthyStatusCount} />
    </StyledGrid>
  );
};
