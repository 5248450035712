import {
  ContactAssistant,
  Navbar as GrimmeNavbar,
  UserSettingsContent,
  UserSettingsMenu,
} from '@grimme/components';
import { signOut, useSession } from '@grimme/next-grimme-auth';
import { ProfileWithoutMachineMappings } from '@grimme/types';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { environment } from '../../../environments/environment';
import { useContactDetails, useGeolocation } from '../../../hooks';
import { getUser, updateProfile } from '../../redux/auth.slice';
import { GlobalSettings } from '../../utils';
import {
  CONTACT_ASSISTANT_COOKIE,
  DEFAULT_MOBILE_PHONE,
} from '../../utils/consts';
import languages from '../../utils/data/languages.json';
import {
  languageExistsAndDifferentFrom,
  syncLanguageInI18n,
} from '../../utils/i18n';
import { WhatsappDialog } from '../whatsapp-dialog';
import { Root } from './styles';
import { UserGreeting } from './user-greeting';
import { UserMenu } from './user-menu/user-menu';
import {
  checkAssistantData,
  getCookie,
  mapAssistantLinks,
  mapContactLinks,
  mapNavigationLinks,
  mapSocialMediaLinks,
  reformatAssistantData,
  reformatWhatsappNumber,
  setCookie,
} from './utils';
import { FEATURE_FLAG_GRID } from '../../utils/feature-flag/flags';
import { useFeatureFlag } from '../../utils/feature-flag';
import { login } from '../../utils/auth/login';
import { register } from '../../utils/auth/register';

export type TNavbar = {
  greeting: GlobalSettings['greeting'];
  loggedInMenu: GlobalSettings['logged_in_menu'];
  loggedOutMenu: GlobalSettings['logged_out_menu'];
  more: GlobalSettings['more'];
  navbarData: GlobalSettings['navbar'];
  navigationLinks: GlobalSettings['navigation_menu'];
  socialLinks: GlobalSettings['social_links'];
  topLinks: GlobalSettings['top_links'];
  withoutContactAssistant: boolean;
};

// TODO: This file is just too big and too complex, we should break it down;
export const Navbar = (props: TNavbar) => {
  const {
    greeting,
    loggedInMenu,
    loggedOutMenu,
    more,
    navbarData,
    navigationLinks,
    socialLinks,
    topLinks,
    withoutContactAssistant,
  } = props;
  const { data: contactAssistantData, error } = useContactDetails();
  const router = useRouter();

  const insights = useAppInsightsContext();
  const { status } = useSession();
  const userData = useSelector(getUser);

  const isUserLoggedIn = status === 'authenticated';
  const isLoading = status === 'loading' || (isUserLoggedIn && !userData);
  const geolocationData = useGeolocation();
  const userIconRef = useRef<HTMLButtonElement>(null);
  const { t, i18n } = useTranslation();
  const defaultWhatsAppState = Boolean(
    typeof localStorage !== 'undefined' &&
      localStorage.getItem('allowWhatsapp'),
  );
  const shouldShowContactOnMount = Boolean(getCookie(CONTACT_ASSISTANT_COOKIE));
  const whatsappText = t('myGRIMME_website_whatsapp_cta', 'Zum Chat');

  const [whatsAppConsent, setWhatsAppConsent] = useState(defaultWhatsAppState);
  const [showConsentDialog, setShowConsentDialog] = useState<boolean>(false);
  const [isShowingContact, setIsShowingContact] = useState<boolean>(
    shouldShowContactOnMount,
  );
  const [isShowingUserSettings, setIsShowingUserSettings] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  const hasGridFlag = useFeatureFlag(FEATURE_FLAG_GRID, {
    email: userData?.Email,
  });

  const hasContactAssistant = checkAssistantData(error, contactAssistantData);

  const showContactAssistant = Boolean(
    !withoutContactAssistant && hasContactAssistant,
  );
  const assistantAvatar =
    contactAssistantData?.photo &&
    `data:image/jpeg;base64,${contactAssistantData?.photo}`;
  const assistantLabel = t('myGRIMME_products_contact_assistant', 'Beratung');
  const assistantName = `${contactAssistantData?.firstName} ${contactAssistantData?.lastName}`;
  const contactNumber =
    contactAssistantData?.phone || contactAssistantData?.mobilePhone;
  const contactAssistantEmail = contactAssistantData?.email || ' ';
  const selectWhatsappNumber =
    contactAssistantData?.mobilePhone || DEFAULT_MOBILE_PHONE;
  const whatsappNumber = reformatWhatsappNumber(selectWhatsappNumber);

  const toggleContactAssistant = () => {
    //cookie has to be a string;
    const assistantCookie = !isShowingContact ? 'enabled' : '';
    setIsShowingContact(!isShowingContact);
    setCookie(CONTACT_ASSISTANT_COOKIE, assistantCookie);
  };

  const onEmailClick = () => {
    window.open(`mailto: ${contactAssistantData?.email}`, '_self');
    insights?.trackEvent({
      name: 'Contacted Rep',
      properties: {
        method: 'email',
        rep: contactAssistantData?.email,
      },
    });
  };

  const { data: sessionData } = useSession();

  const onLanguageChange = async (lng: string) => {
    if (
      status === 'authenticated' &&
      languageExistsAndDifferentFrom(userData?.Language, lng)
    ) {
      const newUserData: ProfileWithoutMachineMappings = {
        ...userData,
        Language: lng.toLowerCase(),
        Companies: [
          {
            ...userData?.Companies?.[0],
            MachineMappings: undefined,
          },
        ],
      };
      dispatch(
        updateProfile({
          email: userData?.Email ?? '',
          data: newUserData,
          silent: true,
          accessToken: sessionData?.accessToken ?? '',
        }),
      );
    }
    await syncLanguageInI18n(lng, i18n);
  };

  const onPhoneClick = () => {
    window.open(`tel:${contactNumber}`);
    insights?.trackEvent({
      name: 'Contacted Rep',
      properties: {
        method: 'phone',
        rep: contactNumber,
      },
    });
  };

  const onWhatsAppClick = () => {
    if (!whatsAppConsent) {
      setShowConsentDialog(true);
    } else {
      window.open(`https://wa.me/${whatsappNumber}`);
      insights?.trackEvent({
        name: 'Contacted Rep',
        properties: {
          method: 'whatsapp',
          rep: whatsappNumber,
        },
      });
    }
  };

  const phoneData = { contactNumber: contactNumber as string, onPhoneClick };
  const emailData = {
    contactAssistantEmail: contactAssistantEmail,
    onEmailClick,
  };
  const whatsappData = {
    onWhatsAppClick,
    whatsappNumber: whatsappNumber as string,
    whatsappText: whatsappText as string,
  };
  const assistantData = reformatAssistantData(
    phoneData,
    emailData,
    whatsappData,
  );
  const assistantLinks = mapAssistantLinks(assistantData);
  const mappedContactLinks = mapContactLinks(assistantData);
  const mappedNavigationLinks = mapNavigationLinks(navigationLinks);
  const mappedSocialLinks = mapSocialMediaLinks(socialLinks);
  const assistantDetails = {
    avatar: assistantAvatar,
    description: assistantLabel,
    name: assistantName,
  };

  const handleOnNavbarLogoClick = () => {
    router.push(environment.grimmeWebsiteUrl);
  };

  const handleOnUserMenuLogoClick = () => {
    router.push('/');
  };

  const handleOnLogout = () => {
    signOut({ callbackUrl: `${environment.redirectUri}/${i18n.language}` });
  };

  return (
    <Root>
      <GrimmeNavbar
        additionalLinks={navbarData?.additional_links}
        isAuthenticated={isUserLoggedIn}
        links={mappedNavigationLinks}
        onLogoClick={handleOnNavbarLogoClick}
        socialLinks={mappedSocialLinks}
        topLinks={topLinks?.links}
        topLinksLabel={topLinks?.title}
      >
        <UserGreeting
          greeting={greeting}
          more={more}
          ref={userIconRef}
          setIsShowingUserSettings={setIsShowingUserSettings}
        />
        <UserSettingsMenu
          anchorEl={userIconRef.current}
          onBackdropClick={() => setIsShowingUserSettings(false)}
          open={isShowingUserSettings}
        >
          <UserSettingsContent
            MenuComponent={
              <UserMenu
                loggedInMenu={loggedInMenu}
                loggedOutMenu={loggedOutMenu}
                more={more}
              />
            }
            contactLinks={mappedContactLinks}
            contactName={assistantName}
            geolocation={geolocationData}
            isLoading={isLoading}
            isSessionValid={isUserLoggedIn}
            isShowingContact={isShowingContact}
            onClose={() => setIsShowingUserSettings(false)}
            onHideContact={toggleContactAssistant}
            onLanguageChange={onLanguageChange}
            onLogin={() => login(hasGridFlag)}
            onLogoClick={handleOnUserMenuLogoClick}
            onLogout={handleOnLogout}
            onRegister={() => register(i18n.language, hasGridFlag)}
            supportedLanguages={languages}
          />
        </UserSettingsMenu>
      </GrimmeNavbar>
      {showContactAssistant && (
        <ContactAssistant
          details={assistantDetails}
          indented
          links={assistantLinks}
          onVisibilityChange={toggleContactAssistant}
          visible={!isShowingContact}
        />
      )}
      <WhatsappDialog
        contactNumber={whatsappNumber}
        open={showConsentDialog}
        onCancel={() => {
          setShowConsentDialog(false);
        }}
        onConfirm={() => {
          setWhatsAppConsent(true);
          setShowConsentDialog(false);
        }}
      />
    </Root>
  );
};
