import { generateUtilityClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const classes = generateUtilityClasses('Timeline', [
  'timelineWrapper',
  'divider',
  'timelineChipsRegular',
  'timelineChipsDataRegular',
  'timelineChipsIconContainer',
  'timelineChipsIconRegular',
  'timelineTitle',
  'timelineChipsContainer',
]);

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.timelineWrapper}`]: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translate(0%,-50%)',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      backgroundColor: theme.palette.grey[100],
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      position: 'relative',
      top: 'auto',
      transform: 'none',
    },
  },

  [`& .${classes.divider}`]: {
    display: 'none',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      display: 'block',
    },
  },

  [`& .${classes.timelineChipsRegular}`]: {
    position: 'relative',
    marginBottom: theme.spacing(3),
    background: theme.palette.primary.main,
    height: '80px',
    borderTopLeftRadius: '40px',
    borderBottomLeftRadius: '40px',
    minWidth: '310px',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      minWidth: 'auto',
      background: 'transparent',
      height: '50px',
    },
    '&:nth-of-type(-n+2)::after': {
      content: '" "',
      display: 'block',
      height: '24px',
      width: '10px',
      background: theme.palette.primary.main,
      position: 'absolute',
      top: '80px',
      left: '35px',
      zIndex: 1,
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        top: '50px',
        left: '20px',
      },
    },
  },

  [`& .${classes.timelineChipsDataRegular}`]: {
    wordBreak: 'break-word',
    color: theme.palette.common.white,
    lineHeight: '1.1',
    marginLeft: theme.spacing(2),
    maxWidth: '200px',
    fontSize: '18px',
    fontWeight: 'lighter',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      color: theme.palette.common.black,
      maxWidth: '150px',
      fontSize: '15px',
    },
  },

  [`& .${classes.timelineChipsIconContainer}`]: {
    width: '80px',
    height: '80px',
    background: theme.palette.common.white,
    borderRadius: '50%',
    boxShadow: '-1px 1px 8px 4px rgba(0,0,0,0.45)',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      width: '50px',
      height: '50px',
    },
  },

  [`& .${classes.timelineChipsIconRegular}`]: {
    width: 'auto',
    height: '35px',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      height: '25px',
    },
  },

  [`& .${classes.timelineTitle}`]: {
    display: 'none',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      display: 'block',
    },
  },

  [`& .${classes.timelineChipsContainer}`]: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      margin: theme.spacing(2),
      marginBottom: 0,
    },
  },
}));
