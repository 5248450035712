import * as React from 'react';

export interface Crumbs {
  key: string;
  url?: string;
}

const BreadcrumbsValueContext = React.createContext<Crumbs[]>([]);

const BreadcrumbsSetterContext = React.createContext<
  React.Dispatch<React.SetStateAction<Crumbs[]>>
>(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
);

export const useBreadcrumbsValue = () => {
  return React.useContext(BreadcrumbsValueContext);
};

export const useBreadcrumbsSetter = () => {
  return React.useContext(BreadcrumbsSetterContext);
};

interface BreadcrumbsProviderProps {
  children: any;
}
export const BreadcrumbsProvider = (props: BreadcrumbsProviderProps) => {
  const [breadcrumbs, setBreadcrumbs] = React.useState<Crumbs[]>([]);
  return (
    <BreadcrumbsSetterContext.Provider value={setBreadcrumbs}>
      <BreadcrumbsValueContext.Provider value={breadcrumbs}>
        {props.children}
      </BreadcrumbsValueContext.Provider>
    </BreadcrumbsSetterContext.Provider>
  );
};
