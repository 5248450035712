import { TitleDivider } from '@grimme/components';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { classes, Root } from './styles';
import { timelineData } from './utils';

export interface ITimelineProps {
  features?: {
    title: string;
  }[];
}

export const Timeline = (props: ITimelineProps) => {
  const { features } = props;

  const { t } = useTranslation();

  return (
    <Root className={classes.timelineWrapper}>
      <Typography variant="h2" className={classes.timelineTitle}>
        {t('myGRIMME_timeline_title', 'My Grimme und Ich')}
      </Typography>
      <TitleDivider className={classes.divider} />
      <div
        data-testid="timeline-chips"
        className={classes.timelineChipsContainer}
      >
        {features &&
          features.map((element, index) => {
            return (
              <div key={index} className={classes.timelineChipsRegular}>
                <div className={classes.timelineChipsIconContainer}>
                  <img
                    src={timelineData[index % 3].image}
                    alt=""
                    className={classes.timelineChipsIconRegular}
                  />
                </div>
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.timelineChipsDataRegular}
                >
                  {element.title}
                </Typography>
              </div>
            );
          })}
      </div>
    </Root>
  );
};
