import { library, IconLookup } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { getCachedAxiosInstance } from '../axios';
import {
  FONT_AWESOME_API_URL,
  FONT_AWESOME_AUTHORIZATION_TOKEN,
  FONT_AWESOME_GRAPHQL_QUERY,
  populateWithIcons,
} from './utils';

const axios = getCachedAxiosInstance();

const getFontAwesomeToken = async () => {
  let token;
  try {
    token = await axios?.post<{ access_token: string }>(
      `${FONT_AWESOME_API_URL}/token`,
      null,
      {
        headers: {
          Authorization: `Bearer ${FONT_AWESOME_AUTHORIZATION_TOKEN}`,
        },
        cache: false,
      },
    );
  } catch (error) {
    console.log('Fetching font awesome token error:', error);
  }
  return token;
};

const getFontAwesomeIconData = async () => {
  const token = await getFontAwesomeToken();
  let iconData;
  try {
    iconData = await axios?.post(
      FONT_AWESOME_API_URL,
      {
        query: FONT_AWESOME_GRAPHQL_QUERY,
      },
      {
        headers: {
          Authorization: `Bearer ${token?.data.access_token}`,
        },
        cache: false,
      },
    );
  } catch (error) {
    console.log('Fetching font awesome icons error: ', error);
  }
  return iconData;
};

export const getIcons = async (...iconLookups: IconLookup[]) => {
  const iconLookupStrings = iconLookups.map((i) => `${i.prefix}|${i.iconName}`);
  const fontAwesomeIconData = await getFontAwesomeIconData();
  const standardIcons = [...Object.values(fas), ...Object.values(fab)];
  const customIcons = populateWithIcons(fontAwesomeIconData);
  let allFontAwesomeIcons = customIcons
    ? standardIcons.concat(customIcons)
    : standardIcons;

  if (iconLookups.length > 0) {
    allFontAwesomeIcons = allFontAwesomeIcons.filter((i) =>
      iconLookupStrings.includes(`${i.prefix}|${i.iconName}`),
    );
  }
  library.add(...allFontAwesomeIcons);
  return allFontAwesomeIcons;
};
