import Image, { ImageProps } from 'next/image';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ImageWithFallbackProps
  extends Omit<
    ImageProps,
    'blurDataURL' | 'onError' | 'placeholder' | 'src' | 'unoptimized'
  > {
  fallback: string;
  imgUrl: string;
}

export const ImageWithFallback = ({
  alt,
  fallback,
  imgUrl,
  quality,
  ...props
}: ImageWithFallbackProps) => {
  const [imgError, setImgError] = useState<boolean>(false);
  const { t } = useTranslation();

  const unknownAlt = t('myGRIMME_core_unknown', 'Unknown');

  const handleError = () => {
    setImgError(true);
  };

  const hasImgUrl = imgUrl !== '' && !imgError;
  const imgSrc = hasImgUrl ? imgUrl : fallback;
  const nextImgPlaceholder = hasImgUrl ? 'blur' : 'empty';

  return (
    <Image
      alt={alt || unknownAlt}
      blurDataURL={imgUrl}
      onError={handleError}
      placeholder={nextImgPlaceholder}
      quality={quality || 100}
      src={imgSrc}
      unoptimized
      {...props}
    />
  );
};
