import Head from 'next/head';
import { environmentConstants } from '../../utils/config';

export interface SeoHeaders {
  title?: string;
  description?: string;
}

export const SeoHeader = (pageProps: SeoHeaders) => {
  return (
    <Head>
      <title key="title">{pageProps.title || 'myGRIMME'}</title>
      <meta
        name="viewport"
        key="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <link
        key="preconnect-apiurl"
        rel="preconnect"
        href={environmentConstants.apiUrl}
        crossOrigin="anonymous"
      />
      <link
        key="preconnect-translationurl"
        rel="preconnect"
        href={environmentConstants.translationApi}
        crossOrigin="anonymous"
      />
      <link
        rel="canonical"
        href={`${environmentConstants.redirectUri}/${environmentConstants.defaultLanguage}`}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png?v=1.0.0"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png?v=1.0.0"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png?v=1.0.0"
      />
      <link rel="manifest" href="/site.webmanifest?v=1.0.0" />
      <link
        rel="mask-icon"
        href="/safari-pinned-tab.svg?v=1.0.0"
        color="#c31924"
      />
      <link rel="shortcut icon" href="/favicon.ico?v=1.0.0" />
      <meta name="msapplication-TileColor" content="#c31924" />
      <meta name="theme-color" content="#c31924" />
      <link rel="icon" type="image/svg+xml" href="/assets/img/favicon.svg" />
      <link rel="icon" type="image/png" href="/assets/img/favicon.png" />
      <meta
        name="description"
        key="description"
        content={
          pageProps.description ||
          'myGRIMME – Endkundenportal mit digitaler Maschinenakte, Betriebsanleitungen, Ersatzteillisten, Dokumentation, Maschinendaten, Online Shop für Ersatzteile.'
        }
      />
    </Head>
  );
};
