import { notificationsActions, notificationType } from '@grimme/components';
import {
  MachineDocumentListResponse,
  SerialMachineData,
  UpsertMachineRequestData,
} from '@mygrimme/types';
import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  addMachine as addMachineQuery,
  deleteMachineQuery,
  downloadDocument,
  editMachineName as editMachineNameQuery,
  getDocumentsBySerial,
  getMachineBySerial,
  getMachines,
} from '../utils/ApiQuery';
import { loadingStatus, LocalMachine } from './utils';

export const MACHINES_FEATURE_KEY = 'machines';
export interface MachinesState extends EntityState<LocalMachine> {
  documentDownloadStatus: loadingStatus;
  downloadDocId?: string;
  error?: string;
  loadingStatus: loadingStatus;
  machineDetailsLoadingStatus: loadingStatus;
  machineDocumentsLoadingStatus: loadingStatus;
  editMachineNameStatus: loadingStatus;
  deleteMachineStatus: loadingStatus;
}

export const machinesAdapter = createEntityAdapter<LocalMachine>({
  selectId: (entity) => entity.SerialNumber as string,
});

export const fetchMachines = createAsyncThunk(
  'machines/fetch',
  async (
    args: {
      serials: string[];
      language: string;
      accessToken: string;
    },
    thunkAPI,
  ) => getMachines(args.serials, args.language, args.accessToken),
);

export const fetchSingleMachineBySerial = createAsyncThunk(
  'machines/:serial/fetch',
  async (
    args: {
      serial: string;
      language: string;
      accessToken: string;
    },
    thunkAPI,
  ) => getMachineBySerial(args.serial, args.language, args.accessToken),
);

export const addMachine = createAsyncThunk(
  'machines/addMachine',
  async (
    args: {
      email: string;
      mappingData: UpsertMachineRequestData;
      accessToken: string;
    },
    { dispatch },
  ) => {
    try {
      const response = await addMachineQuery(
        args.email,
        args.mappingData,
        args.accessToken,
      );
      dispatch(
        notificationsActions.addSuccessNotification({
          message: 'myGRIMME_core_machine_add_success',
        }),
      );
      return response;
    } catch (error) {
      dispatch(
        notificationsActions.addErrorNotification({
          message: 'myGRIMME_core_machine_add_failure',
        }),
      );
    }
  },
);

export const editMachineName = createAsyncThunk(
  'machines/editMachineName',
  async (
    args: {
      email: string;
      serial: string;
      name: string;
      silent?: boolean;
      code?: notificationType;
      accessToken: string;
    },
    { dispatch },
  ) => {
    try {
      const response = await editMachineNameQuery(
        args.email,
        args.serial,
        args.name,
        args.accessToken,
      );
      if (!args?.silent) {
        dispatch(
          notificationsActions.addSuccessNotification({
            message: 'myGRIMME_core_machine_rename_success',
            code: args?.code,
          }),
        );
      }
      return response;
    } catch (error) {
      if (!args.silent) {
        dispatch(
          notificationsActions.addErrorNotification({
            message: 'myGRIMME_core_machine_rename_failure',
            code: args?.code,
          }),
        );
      }
    }
  },
);

export const deleteMachine = createAsyncThunk(
  'machines/:email/:serial/delete',
  async (
    args: {
      email: string;
      serial: string;
      language: string;
      silent?: boolean;
      code?: notificationType;
      accessToken: string;
    },
    { dispatch },
  ) => {
    try {
      const response = await deleteMachineQuery(
        args.email,
        args.serial,
        args.language,
        args.accessToken,
      );
      if (!args.silent) {
        dispatch(
          notificationsActions.addSuccessNotification({
            message: 'myGRIMME_core_machine_delete_success',
            code: args?.code,
          }),
        );
      }
      return response;
    } catch (error) {
      if (!args.silent) {
        dispatch(
          notificationsActions.addErrorNotification({
            message: 'myGRIMME_core_machine_delete_failure',
            code: args?.code,
          }),
        );
      }
    }
  },
);

export const resetDeleteMachineStatus = createAction('machines/redirect/reset');

export const fetchDocumentsBySerial = createAsyncThunk(
  'machines/documents/:serial',
  async (args: { serial: string; language: string; accessToken: string }) =>
    getDocumentsBySerial(args.serial, args.language, args.accessToken),
);

export const downloadSingleDocument = createAsyncThunk(
  'machines/documents/download',
  async (args: {
    docId: string;
    encodedDocumentHash: string;
    filename: string;
    accessToken: string;
  }) => {
    return downloadDocument(
      args.docId,
      args.encodedDocumentHash,
      args.filename,
      args.accessToken,
    );
  },
);

export const initialMachinesState: MachinesState =
  machinesAdapter.getInitialState({
    documentDownloadStatus: loadingStatus.NOT_LOADED,
    downloadDocId: undefined,
    error: undefined,
    loadingStatus: loadingStatus.NOT_LOADED,
    machineDetailsLoadingStatus: loadingStatus.NOT_LOADED,
    machineDocumentsLoadingStatus: loadingStatus.NOT_LOADED,
    editMachineNameStatus: loadingStatus.NOT_LOADED,
    deleteMachineStatus: loadingStatus.NOT_LOADED,
  });

export const machinesSlice = createSlice({
  name: MACHINES_FEATURE_KEY,
  initialState: initialMachinesState,
  reducers: {
    add: machinesAdapter.addOne,
    remove: machinesAdapter.removeOne,
    resetEditMachineNameStatus: (state) => {
      state.loadingStatus = loadingStatus.NOT_LOADED;
    },
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMachines.pending, (state: MachinesState) => {
        state.loadingStatus = loadingStatus.LOADING;
      })
      .addCase(
        fetchMachines.fulfilled,
        (
          state: MachinesState,
          action: PayloadAction<
            SerialMachineData[],
            string,
            {
              arg: {
                serials: string[];
                language: string;
              };
              requestId: string;
            }
          >,
        ) => {
          machinesAdapter.upsertMany(state, action.payload);
          state.loadingStatus = loadingStatus.LOADED;
        },
      )
      .addCase(fetchMachines.rejected, (state: MachinesState, action) => {
        state.loadingStatus = loadingStatus.ERROR;
        state.error = action.error.message;
      })
      .addCase(fetchSingleMachineBySerial.pending, (state: MachinesState) => {
        state.machineDetailsLoadingStatus = loadingStatus.LOADING;
        state.machineDocumentsLoadingStatus = loadingStatus.LOADING;
      })
      .addCase(
        fetchSingleMachineBySerial.fulfilled,
        (state: MachinesState, action) => {
          machinesAdapter.upsertOne(state, action.payload);
          state.machineDetailsLoadingStatus = loadingStatus.LOADED;
          state.machineDocumentsLoadingStatus = loadingStatus.LOADED;
        },
      )
      .addCase(
        fetchSingleMachineBySerial.rejected,
        (state: MachinesState, action) => {
          state.machineDetailsLoadingStatus = loadingStatus.ERROR;
          state.machineDocumentsLoadingStatus = loadingStatus.ERROR;
          state.error = action.error.message;
        },
      )
      .addCase(fetchDocumentsBySerial.pending, (state: MachinesState) => {
        state.machineDocumentsLoadingStatus = loadingStatus.LOADING;
      })
      .addCase(
        fetchDocumentsBySerial.fulfilled,
        (
          state: MachinesState,
          action: PayloadAction<
            {
              serial: string;
              documents: MachineDocumentListResponse;
            },
            string,
            {
              arg: {
                serial: string;
                language: string;
              };
              requestId: string;
            }
          >,
        ) => {
          state.machineDocumentsLoadingStatus = loadingStatus.LOADED;
          machinesAdapter.updateOne(state, {
            changes: {
              documents: {
                ...state.entities[action.payload.serial]?.documents,
                [action.meta.arg.language]: action.payload.documents,
              },
            },
            id: action.payload.serial,
          });
        },
      )
      .addCase(
        fetchDocumentsBySerial.rejected,
        (state: MachinesState, action) => {
          state.machineDocumentsLoadingStatus = loadingStatus.ERROR;
          state.error = action.error.message;
        },
      )
      .addCase(
        downloadSingleDocument.pending,
        (state: MachinesState, action) => {
          state.documentDownloadStatus = loadingStatus.LOADING;
          state.downloadDocId = action?.meta?.arg?.docId;
        },
      )
      .addCase(downloadSingleDocument.fulfilled, (state: MachinesState) => {
        state.documentDownloadStatus = loadingStatus.LOADED;
        state.downloadDocId = undefined;
      })
      .addCase(
        downloadSingleDocument.rejected,
        (state: MachinesState, action) => {
          state.documentDownloadStatus = loadingStatus.ERROR;
          state.downloadDocId = undefined;
          state.error = action.error.message;
        },
      )
      .addCase(editMachineName.pending, (state: MachinesState) => {
        state.editMachineNameStatus = loadingStatus.LOADING;
      })
      .addCase(editMachineName.fulfilled, (state: MachinesState, action) => {
        state.editMachineNameStatus = loadingStatus.LOADED;
      })
      .addCase(editMachineName.rejected, (state: MachinesState, action) => {
        state.editMachineNameStatus = loadingStatus.ERROR;
        state.error = action.error.message;
      })
      .addCase(deleteMachine.pending, (state: MachinesState) => {
        state.deleteMachineStatus = loadingStatus.LOADING;
      })
      .addCase(deleteMachine.fulfilled, (state: MachinesState, action) => {
        state.deleteMachineStatus = loadingStatus.LOADED;
      })
      .addCase(deleteMachine.rejected, (state: MachinesState, action) => {
        state.deleteMachineStatus = loadingStatus.ERROR;
        state.error = action.error.message;
      })
      .addCase(resetDeleteMachineStatus, (state: MachinesState) => {
        state.deleteMachineStatus = loadingStatus.NOT_LOADED;
      });
  },
});

export const machinesReducer = machinesSlice.reducer;

export const machinesActions = machinesSlice.actions;

const { selectAll, selectEntities } = machinesAdapter.getSelectors();

export const getMachinesState = (rootState: {
  [MACHINES_FEATURE_KEY]: MachinesState;
}): MachinesState => rootState[MACHINES_FEATURE_KEY];

export const selectAllMachines = createSelector(getMachinesState, selectAll);

export const selectMachinesEntities = createSelector(
  getMachinesState,
  selectEntities,
);

export const selectMachinesEntityBySerial = (serial: string) =>
  createSelector(selectMachinesEntities, (state) => state[serial]);

export const isLoadingMachines = createSelector(
  getMachinesState,
  (state) => state.loadingStatus === loadingStatus.LOADING,
);

export const areMachinesLoaded = createSelector(
  getMachinesState,
  (state) => state.loadingStatus === loadingStatus.LOADED,
);

export const areMachineDetailsLoaded = createSelector(
  getMachinesState,
  (state) => state.machineDetailsLoadingStatus === loadingStatus.LOADED,
);

export const isMachinePresent = (serial: string) =>
  createSelector(getMachinesState, (state) =>
    state.ids.includes(serial.toString()),
  );

export const areMachinesDifferentFromProfile = (
  machineIdsFromProfile: EntityId[],
) =>
  createSelector(getMachinesState, (state) =>
    machineIdsFromProfile.some((mId) => !state.ids.includes(mId)),
  );

export const selectNotLoadedSerials = (serials: Array<string>) =>
  createSelector(getMachinesState, (state) =>
    serials
      .filter((mId) => !state.ids.includes(mId))
      .map((id) => id.toString()),
  );

export const selectMachineDocuments = (serial: string, language: string) =>
  createSelector(
    getMachinesState,
    (state) => state.entities[serial]?.documents?.[language],
  );

export const isDocumentDownloading = createSelector(
  getMachinesState,
  (state) => state.documentDownloadStatus === loadingStatus.LOADING,
);

export const selectDownloadingDocumentId = createSelector(
  getMachinesState,
  (state) => state.downloadDocId,
);

export const selectEditMachineNameStatus = createSelector(
  getMachinesState,
  (state) => state.editMachineNameStatus,
);

export const selectDeleteMachineStatus = createSelector(
  getMachinesState,
  (state) => state.deleteMachineStatus,
);
