import { useSession } from '@grimme/next-grimme-auth';
import { useSelector } from 'react-redux';
import { getUser } from '../../app/redux/auth.slice';

export const useFormattedProfileData = (
  moreText: string,
  greetingText?: string,
) => {
  const profileData = useSelector(getUser);

  const { status } = useSession();

  const isUserLoggedIn = status === 'authenticated';

  const isUserDataLoaded = Boolean(isUserLoggedIn && profileData);

  const company =
    profileData &&
    profileData.Companies &&
    (profileData.Companies.length > 1
      ? `${profileData.Companies[0].Name} ( +${
          profileData.Companies.length - 1
        } ${moreText})`
      : `${profileData.Companies[0].Name}`);
  const fullName =
    profileData && profileData.FirstName + ' ' + profileData.LastName;
  const isLoading = status === 'loading' || (isUserLoggedIn && !profileData);
  const userGreeting =
    profileData && `${greetingText} ${profileData.FirstName}`;
  const initialsFirstName =
    profileData && profileData.FirstName && profileData.FirstName.charAt(0);
  const initialsLastName =
    profileData && profileData.LastName && profileData.LastName.charAt(0);
  const initials = `${initialsFirstName}${initialsLastName}`;

  return {
    company,
    fullName,
    initials,
    isLoading,
    isUserDataLoaded,
    isUserLoggedIn,
    userGreeting,
  };
};
