import * as React from 'react';
import { I18nextProvider } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUser } from '../redux/auth.slice';
import { useConfiguration } from './config';
import i18n from './i18n';

export type Language = {
  id: string;
  name: string;
  value: string;
};

const SupportedLanguagesContext = React.createContext<Language[]>([]);

type LanguageProviderProps = React.PropsWithChildren<{
  locale: string;
}>;

export const LanguageProvider = (props: LanguageProviderProps) => {
  const { children, locale } = props;
  const user = useSelector(getUser);

  const config = useConfiguration();
  const [supportedLanguages, setSupportedLanguages] = React.useState<
    Language[]
  >([]);

  React.useEffect(() => {
    let isMounted = true;
    async function loadSupportedLanguages() {
      const response = await fetch(`${config.translationApi}/languages`);
      const languages = (await response.json()) as Language[];
      if (isMounted) {
        setSupportedLanguages(languages);
      }
    }
    try {
      loadSupportedLanguages();
    } catch (error) {
      console.log('TranslationsAPI seems to be down', error);
    }
    return () => {
      isMounted = false;
    };
  }, [config.translationApi]);

  React.useEffect(() => {
    async function setLanguage(lang: string) {
      await i18n.changeLanguage(lang);
    }
    if (user && user.Language) {
      setLanguage(user.Language.toLowerCase());
    } else if (locale && locale !== i18n.language) {
      setLanguage(locale);
    }
  }, [user, locale]);

  return (
    <SupportedLanguagesContext.Provider value={supportedLanguages}>
      <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
    </SupportedLanguagesContext.Provider>
  );
};

export const useSupportedLanguages = () => {
  return React.useContext(SupportedLanguagesContext);
};
