import { faCircleCheck, faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '@grimme/theme';
import { InputAdornment, Tooltip, Typography } from '@mui/material';

export interface AdornmentTooltipProps {
  tooltipText?: string;
}

export interface ValidationAdornmentProps {
  customAdornment?: JSX.Element | string;
  isValid?: boolean;
  tooltipText?: string;
}

export const AdornmentTooltip = (props: AdornmentTooltipProps) => {
  const { tooltipText } = props;
  const color = theme.palette.info.main;

  return (
    <Tooltip
      data-testid="adornment-tooltip"
      enterTouchDelay={0}
      leaveTouchDelay={2500}
      placement="top-end"
      title={<Typography variant="body2">{tooltipText}</Typography>}
    >
      <FontAwesomeIcon color={color} icon={faCircleInfo} size="lg" />
    </Tooltip>
  );
};

/**
 * TODO: currently, in our projects, there is no design/behavior implemented for invalid inputs.
 * We have a US for doing it. That's why, no adornment is displayed here for those cases.
 */
const noAdornment = null;

export const ValidationAdornment = (props: ValidationAdornmentProps) => {
  const { customAdornment, isValid, tooltipText } = props;
  const colorValid = theme.palette.success.main;

  const adornmentForValidInput = customAdornment ? (
    customAdornment
  ) : (
    <FontAwesomeIcon
      color={colorValid}
      data-testid="valid-adornment-icon"
      icon={faCircleCheck}
      size="lg"
    />
  );

  return (
    <>
      {isValid && (
        <InputAdornment position="end">{adornmentForValidInput}</InputAdornment>
      )}
      {!isValid && !tooltipText && noAdornment}
      {!isValid && tooltipText && (
        <InputAdornment position="end">
          <AdornmentTooltip tooltipText={tooltipText} />
        </InputAdornment>
      )}
    </>
  );
};
