import { faHouse } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Breadcrumbs } from '@grimme/components';
import { Link } from 'react-router-dom';
import { environment } from '../../../environments/environment';

const websiteUrl = environment?.grimmeWebsiteUrl;

export type TBreadcrumbs = {
  items: {
    label: string;
    url?: string;
  }[];
};

export const BreadcrumbsWrapper = ({ items }: TBreadcrumbs) => {
  return (
    <Breadcrumbs variant="dark">
      <Breadcrumb href={websiteUrl}>
        <FontAwesomeIcon icon={faHouse}></FontAwesomeIcon>
      </Breadcrumb>
      {items?.map(({ label, url }, index) => {
        const breadcrumb = (
          <Breadcrumb key={index} active={index === items.length - 1}>
            {label}
          </Breadcrumb>
        );

        if (index === items.length - 1) {
          return breadcrumb;
        }

        return (
          <Link key={index} to={url && url !== '' ? url : ''}>
            {breadcrumb}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
