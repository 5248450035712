import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  getMachineConfigurationBySerial,
  MachineConfigurationObject,
} from '../utils/ApiQuery';
import { loadingStatus } from './utils';

export const MACHINE_CONFIGURATIONS_FEATURE_KEY = 'machine-configurations';

export interface MachineConfigurationsState
  extends EntityState<MachineConfigurationObject> {
  error?: string;
  status: loadingStatus;
}

export const machineConfigurationsAdapter =
  createEntityAdapter<MachineConfigurationObject>({
    selectId: (entity) => entity.serial,
  });

export const fetchSingleMachineConfigurationBySerial = createAsyncThunk(
  'machines/:serial/configuration/fetch',
  async (
    args: { serial: string; language: string; accessToken: string },
    thunkAPI,
  ) =>
    getMachineConfigurationBySerial(
      args.serial,
      args.language,
      args.accessToken,
    ),
);

export const initialMachineConfigurationsState: MachineConfigurationsState =
  machineConfigurationsAdapter.getInitialState({
    error: undefined,
    status: loadingStatus.NOT_LOADED,
  });

export const machineConfigurationsSlice = createSlice({
  name: MACHINE_CONFIGURATIONS_FEATURE_KEY,
  initialState: initialMachineConfigurationsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchSingleMachineConfigurationBySerial.pending,
        (state: MachineConfigurationsState) => {
          state.status = loadingStatus.LOADING;
        },
      )
      .addCase(
        fetchSingleMachineConfigurationBySerial.fulfilled,
        (
          state: MachineConfigurationsState,
          action: PayloadAction<MachineConfigurationObject>,
        ) => {
          machineConfigurationsAdapter.setAll(state, [action.payload]);
          state.error = undefined;
          state.status = loadingStatus.LOADED;
        },
      )
      .addCase(
        fetchSingleMachineConfigurationBySerial.rejected,
        (state: MachineConfigurationsState, action) => {
          state.status = loadingStatus.ERROR;
          state.error = action.error.message;
        },
      );
  },
});

export const machineConfigurationsReducer = machineConfigurationsSlice.reducer;

export const machineConfigurationsActions = machineConfigurationsSlice.actions;

const { selectEntities } = machineConfigurationsAdapter.getSelectors();

export const getMachineConfigurationsState = (rootState: {
  [MACHINE_CONFIGURATIONS_FEATURE_KEY]: MachineConfigurationsState;
}): MachineConfigurationsState => rootState[MACHINE_CONFIGURATIONS_FEATURE_KEY];

export const selectMachineConfigurationsEntities = createSelector(
  getMachineConfigurationsState,
  selectEntities,
);

export const selectMachineConfigurationsEntityBySerial = (serial: string) =>
  createSelector(
    selectMachineConfigurationsEntities,
    (state) => state[serial]?.configuration,
  );

export const machineConfigurationsStatus = createSelector(
  getMachineConfigurationsState,
  (state) => state.status,
);

export const machineConfigurationsErrorMessage = createSelector(
  getMachineConfigurationsState,
  (state) => state.error,
);
