import { signIn } from '@grimme/next-grimme-auth';
import { environment } from '../../../environments';

export const login = (hasGridFlag: boolean) => {
  if (!hasGridFlag) return signIn();

  const queryString = new URLSearchParams({
    callbackUrl: window.location.href,
    useGrid: 'true',
  }).toString();

  const loginUrl = new URL(`login?${queryString}`, environment.authServer);
  window.location.assign(loginUrl);
};
