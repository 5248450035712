import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { GlobalSettings } from '../../utils';

export const getFooterData = (data: GlobalSettings['footer']) => {
  return {
    title: data?.title,
    company_information: [
      {
        company_name: data?.company_name,
        street_address: data?.street_address,
        postal_code: data?.postal_code,
        city: data?.city,
        phone: data?.phone,
        email: data?.email,
        website: data?.website,
      },
    ],
    legal_information_links: data?.legal_information_links,
  };
};

export const getNavigationLinks = (
  navigationLinks: GlobalSettings['navigation_menu'],
) => {
  return navigationLinks
    ?.map((l) => l.fields)
    ?.map((link) => ({
      label: link.label,
      url: link.url,
    }));
};

export const getSocialLinks = (links: GlobalSettings['social_links']) => {
  return links?.links?.map((socialLink) => ({
    url: socialLink.url,
    icon: {
      prefix: socialLink.icon_type,
      iconName: socialLink.icon,
    } as IconLookup,
  }));
};

export const getSitemap = (
  data: GlobalSettings['footer'],
  navigation,
  links: GlobalSettings['top_links'],
) => {
  return [
    {
      caption: data?.sitemap_title,
      links: navigation,
    },
    {
      caption: links?.title,
      links: links?.links,
    },
  ];
};
