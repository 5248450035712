import {
  AllInOneProfileData,
  Dealer,
  DealersResponse,
  FakePermission,
  Geolocation,
  GSCSerialResponse,
  MachineConfiguration,
  MachineDocumentListResponse,
  MachineMapping,
  ProfileWithoutMachineMappings,
  SerialMachineData,
  SortCropsBody,
  UpdateProfileResponseData,
  UpsertMachineRequestData,
  UpsertMachineResponseData,
} from '@mygrimme/types';
import { Account, PostAccount } from '../pages/admin/utils/utils';
import { CropFormData, CropVariety } from '../pages/crops/utils';
import { GrimmeApiCrop } from '../pages/crops/utils/types';
import { GetWearPartsResponse } from '../pages/machine-details/hooks/types';
import { LocalMachine } from '../redux/utils';
import { createApiUrl } from './ApiQuery';

export interface ErrorBody {
  message?: string;
  details?: Record<string, string>;
}

export class APIError extends Error {
  details: Record<string, string>;

  constructor(response: ErrorBody) {
    super(response.message);
    this.message = response.message ?? '';
    this.details = response.details ?? {};
  }
}

export class NetworkError extends Error {
  constructor(response: unknown) {
    super('NetworkError');
    this.message = 'NetworkError';
  }
}

export interface CallApiProps<Body> {
  id: ApiCallsFunctions;
  endpoint: ReturnType<typeof createApiUrl>;
  language?: string;
  method?: 'get' | 'post' | 'delete' | 'put';
  body?: Body;
  responseType?: 'json' | 'blob';
  accessToken?: string | undefined;
}

export type ApiCallsFunctions = keyof ApiCalls;

export type CacheInvalidationMap = Partial<{
  [key in ApiCallsFunctions]: {
    invalidates?: ApiCallsFunctions[];
    cache?: boolean;
  };
}>;

export type ApiCalls = {
  addMachine: (
    email: string,
    machine: UpsertMachineRequestData,
    accessToken: string,
  ) => Promise<UpsertMachineResponseData | undefined>;
  createAccount: (
    body: PostAccount,
    language: string,
    accessToken: string,
  ) => Promise<AllInOneProfileData | undefined>;
  createCrop: (
    accessToken: string | undefined,
    body: CropFormData,
  ) => Promise<CropVariety | undefined>;
  deleteAccount: (
    email: string,
    accessToken: string,
  ) => Promise<AllInOneProfileData | undefined>;
  deleteCrop: (
    accessToken: string,
    cropType: number,
    cropVarietyId: number,
  ) => Promise<CropVariety | undefined>;
  deleteMachineQuery: (
    email: string,
    serial: string,
    language: string,
    accessToken: string,
  ) => Promise<AllInOneProfileData | undefined>;
  downloadDocument: (
    docId: string,
    encodedDocumentHash: string,
    filename: string | undefined,
    accessToken: string,
  ) => Promise<void>;
  editMachineName: (
    email: string,
    serial: string,
    name: string,
    accessToken: string,
  ) => Promise<MachineMapping | undefined>;
  getAccount: (
    email: string,
    language: string,
    accessToken: string,
  ) => Promise<AllInOneProfileData | undefined>;
  getAccounts: (
    id: string,
    language: string,
    accessToken: string,
  ) => Promise<Account[] | undefined>;
  getCCIAuthToken: (
    sessionCode: string,
    accessToken: string,
  ) => Promise<CCITokenResponse | undefined>;
  getCrops: (
    accessToken: string,
    bussinessRelationId: string,
  ) => Promise<GrimmeApiCrop[]>;
  getDealerById: (
    id: string,
    accessToken: string,
  ) => Promise<Dealer | undefined>;
  getDealers: (
    countryCode: string | undefined,
    language: string | undefined,
    accessToken: string,
  ) => Promise<DealersResponse>;
  getDealersV2: (
    countryCode: string | undefined,
    language: string | undefined,
    accessToken: string,
  ) => Promise<DealersResponse>;
  getGeolocation: (language: string) => Promise<Geolocation | undefined>;
  getMachines: (
    serials: string[],
    language: string,
    accessToken: string,
  ) => Promise<SerialMachineData[]>;
  getDocumentsBySerial: (
    serial: string,
    language: string,
    accessToken: string,
  ) => Promise<{
    serial: string;
    documents: MachineDocumentListResponse;
  }>;
  getMachineBySerial: (
    serial: string,
    language: string,
    accessToken: string,
  ) => Promise<LocalMachine>;
  getMachineByGSCSerial: (
    serial: string,
    language: string,
    accessToken: string,
  ) => Promise<GSCSerialResponse | undefined>;
  getMachineConfigurationBySerial: (
    serial: string,
    language: string,
    accessToken: string,
  ) => Promise<MachineConfigurationObject>;
  getPermissions: (
    language: string,
    accessToken: string,
  ) => Promise<FakePermission[]>;
  /** @deprecated */
  getProfile: (
    email: string,
    language: string,
    accessToken: string,
  ) => Promise<AllInOneProfileData | undefined>;
  getTelemetryData: (
    serials: string[],
    accessToken: string,
    country?: string,
  ) => Promise<TelemetryDataBySerial | undefined>;
  getWearParts: (
    accessToken: string,
    serialnumber: string,
    language: string,
  ) => Promise<GetWearPartsResponse | undefined>;
  getWearPartsExcel: (
    accessToken: string,
    serialnumber: string,
    language: string,
  ) => Promise<Blob | undefined>;
  sortCrop: (
    accesToken: string,
    body: SortCropsBody,
    cropType: number,
  ) => Promise<CropVariety[] | undefined>;
  updateAccount: (
    email: string,
    permissions: FakePermission[],
    language: string,
    accessToken: string,
  ) => Promise<AllInOneProfileData | undefined>;
  updateCrop: (
    accessToken: string | undefined,
    body: CropFormData,
  ) => Promise<CropVariety | undefined>;
  updateProfile: (
    email: string,
    data: ProfileWithoutMachineMappings,
    accessToken: string,
  ) => Promise<UpdateProfileResponseData | undefined>;
};

export type MachineConfigurationObject = {
  serial: string;
  configuration: MachineConfiguration[];
};

// TODO: move to types
export interface CCITokenResponse {
  jwt: string;
  message: string;
  state: string;
}

export enum TelemetryOnlineStatus {
  OFFLINE = 0,
  ONLINE = 1,
  ERROR = 2,
}

export interface TelemetryData {
  status?: TelemetryOnlineStatus;
  hectares?: number;
  hectares_unit?: string;
  engine_time?: number;
  engine_time_unit?: string;
  lastOnlineAt?: string;
  timestamp?: string;
}

export type TelemetryDataBySerial = Record<string, TelemetryData>;
