import i18n from './i18n.json';
import meta from './meta.json';

interface MetaData {
  [key: string]: {
    [key: string]: string;
  };
}

export const title: MetaData = meta.title;
export const description: MetaData = meta.description;
export const locales = i18n.locales;
export const defaultLocale = i18n.defaultLocale;
