import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, alpha, generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses('Status', [
  'root',
  'expanded',
  'root2',
  'content',
  'expanded2',
  'backdrop',
  'accountStatusTitle',
  'accountStatusCount',
  'accountStatusDetails',
  'arrowIconBox',
  'arrowIconDefault',
  'arrowIconDown',
]);

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.backdrop}`]: {
    zIndex: 99999,
    background: alpha(theme.palette.common.white, 0.9),
  },
  [`& .${classes.root}`]: {
    background: 'transparent',
    boxShadow: 'none',
    marginLeft: theme.spacing(-1),
  },
  [`& .${classes.root2}`]: {
    padding: 'unset',
  },
  [`& .${classes.accountStatusTitle}`]: {
    width: '100%',
    color: theme.palette.common.white,
  },
  [`& .${classes.accountStatusCount}`]: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginLeft: theme.spacing(1),
    },
  },
  [`& .${classes.accountStatusDetails}`]: {
    color: theme.palette.common.white,
  },
  [`& .${classes.arrowIconBox}`]: {
    margin: theme.spacing('auto', '4px', 'auto', '0px'),
    marginRight: theme.spacing(0.5),
    color: theme.palette.common.white,
  },
  [`& .${classes.arrowIconDefault}`]: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    transform: 'rotate(0deg)',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
  [`& .${classes.arrowIconDown}`]: {
    transform: 'rotate(90deg)',
  },
}));

export const StyledCheckFilled = styled(FontAwesomeIcon)(({ theme }) => ({
  [`&`]: {
    color: theme.palette.success.main,
    backgroundColor: theme.palette.common.white,
    borderRadius: 90,
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
}));

export const StyledCrossFilled = styled(FontAwesomeIcon)(({ theme }) => ({
  [`&`]: {
    color: theme.palette.grey[300],
    backgroundColor: theme.palette.common.white,
    borderRadius: 90,
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
}));
