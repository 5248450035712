// some env vars have been injected via Webpack

import { Environment } from './environment';

// check here: apps/core/webpack.config.js
export const environment: Environment = {
  production: true,
  accountUrl:
    process.env.NEXT_PUBLIC_GRID_ACCOUNT_URL || 'https://account.grimme.com',
  authServer: process.env.NEXT_PUBLIC_AUTH_SERVER || 'https://auth.grimme.com',
  agrirouterUrl: process.env.NEXT_PUBLIC_AGRIROUTER_LINK || '/',
  apiUrl: process.env.NEXT_PUBLIC_MY_GRIMME_CORE_API ?? '',
  appVersion: process.env.NEXT_PUBLIC_NPM_PACKAGE_VERSION,
  authScopes: process.env.NEXT_PUBLIC_AUTH_SCOPES?.split(' ') ?? [],
  azureAppConfigEndpoint: process.env.NEXT_PUBLIC_AZURE_APP_CONFIG,
  clientId: process.env.NEXT_PUBLIC_CLIENT_ID ?? '',
  cmsAuthToken: process.env.NEXT_PUBLIC_CMS_AUTH_TOKEN,
  commitHash: process.env.NEXT_PUBLIC_COMMIT_HASH,
  connectivityApiUrl: process.env.NEXT_PUBLIC_CONNECTIVITY_API_URL || '',
  connectivityUrl: process.env.NEXT_PUBLIC_CONNECTIVITY_LINK ?? '',
  googleMapsApiKey: process.env.NEXT_PUBLIC_APP_GOOGLEMAPS_KEY ?? '',
  gridUrl: process.env.NEXT_PUBLIC_GRID_API_URL ?? '',
  grimmeWebsiteUrl:
    process.env.NEXT_PUBLIC_GRIMME_WEBSITE_URL || 'https://grimme.com',
  insightsKey: process.env.NEXT_PUBLIC_APP_INSIGHTS_KEY,
  knownAuthorities: process.env.NEXT_PUBLIC_KNOWN_AUTHORITIES?.split(' ') ?? [],
  maintenanceMode: process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true',
  myJobsUrl: process.env.NEXT_PUBLIC_MY_JOBS ?? '',
  navItems: process.env.NEXT_PUBLIC_NAV_ITEMS ?? '',
  newsletterSubscriptionApiUrl:
    process.env.NEXT_PUBLIC_NEWSLETTER_SUBSCRIPTION_API ?? '',
  onlineSalesApiUrl: process.env.NEXT_PUBLIC_ONLINE_SALES_API ?? '',
  projectName: process.env.NEXT_PUBLIC_NPM_PACKAGE_NAME,
  redirectUri: process.env.NEXT_PUBLIC_REDIRECT_URI ?? '',
  shopUrl:
    process.env.NEXT_PUBLIC_GRIMME_SHOP_LINK ?? 'https://shop.grimme.com',
  shopUrlUk:
    process.env.NEXT_PUBLIC_GRIMME_UK_SHOP_LINK ??
    'https://shop.grimme.co.uk/en',
  registrationLink: process.env.NEXT_PUBLIC_REGISTRATION_LINK ?? '',
  remoteViewPort: process.env.NEXT_PUBLIC_REMOTE_VIEW_PORT,
  remoteViewUrl: process.env.NEXT_PUBLIC_REMOTE_VIEW_URL,
  telemetryLink: process.env.NEXT_PUBLIC_TELEMETRY_LINK ?? '',
  tokenAuthority: process.env.NEXT_PUBLIC_TOKEN_AUTHORITY ?? '',
  translationApi: process.env.NEXT_PUBLIC_TRANSLATION_API ?? '',
  usedMachinesLink: process.env.NEXT_PUBLIC_USED_MACHINES_LINK ?? '',
};
