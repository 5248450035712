import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TelemetryOnlineStatus } from '../../utils/ApiQueryTypes';
import { DEFAULT_MACHINE_IMAGE } from '../../utils/consts';
import { getTelemetryStatusTranslation } from '../../utils/machines';
import { reformatDate } from '../../utils/utils';
import { ImageWithFallback } from '../image-with-fallback';
import { TelemetryStatusIcon } from '../status';
import { classes, StyledGrid } from './MachineCard.styles';

interface MachineCardProps {
  image: string;
  isTelemetryActive?: boolean;
  lastOnlineAt?: string;
  loading?: boolean;
  name?: string;
  status?: TelemetryOnlineStatus;
  timestamp?: string;
}

interface TelemetryProps {
  lastOnlineAt?: string;
  status?: TelemetryOnlineStatus;
}

export const Telemetry = (props: TelemetryProps) => {
  const { lastOnlineAt, status } = props;
  const { t } = useTranslation();
  return (
    <>
      <TelemetryStatusIcon status={status} />
      <Typography variant="body2" className={classes.telemetryStatus}>
        {getTelemetryStatusTranslation(status, lastOnlineAt, t)}
      </Typography>
    </>
  );
};

export const MachineCard = ({
  image,
  isTelemetryActive,
  lastOnlineAt,
  name,
  status,
}: MachineCardProps) => {
  const reformatedLastOnlineAt = lastOnlineAt && reformatDate(lastOnlineAt);

  return (
    <StyledGrid className={classes.grid}>
      <div className={classes.cardContentWrapper}>
        <div className={classes.cardContent}>
          <ImageWithFallback
            alt={name}
            fallback={DEFAULT_MACHINE_IMAGE}
            imgUrl={image}
            layout="fill"
            objectFit="contain"
          />
        </div>
      </div>
      <Typography variant="body2" className={classes.machineName}>
        {name}
      </Typography>
      {isTelemetryActive && (
        <div className={classes.telemetryContainer}>
          <Telemetry lastOnlineAt={reformatedLastOnlineAt} status={status} />
        </div>
      )}
    </StyledGrid>
  );
};
