export function removeOldPoliciesFromStorage() {
  const oldPolicies = ['b2c_1_signinpolicy'];
  if (typeof localStorage === 'undefined') {
    return;
  }
  Object.keys(localStorage).forEach((key) => {
    if (oldPolicies.some((policy) => key.includes(policy))) {
      localStorage.removeItem(key);
    }
  });
}
