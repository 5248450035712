import {
  IconName,
  IconPrefix,
  IconProp,
} from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import * as H from 'history';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { StyledGrid, classes } from './styles';

export type LinkCardProps = {
  icon: IconProp | [IconPrefix, IconName] | string[];
  image?: string;
  onClick?: LinkProps['onClick'];
  inactiveCardClick?: React.MouseEventHandler<HTMLDivElement>;
  target?: string;
  title: string;
  disabled?: boolean;
  to?:
    | H.LocationDescriptor<Record<string, unknown>>
    | ((
        location: H.Location<Record<string, unknown>>,
      ) => H.LocationDescriptor<Record<string, unknown>>);
  'data-testid'?: string;
};

export const LinkCard = (props: PropsWithChildren<LinkCardProps>) => {
  const {
    children,
    disabled,
    icon,
    image,
    inactiveCardClick,
    onClick,
    target,
    title,
    to,
    ...rest
  } = props;
  const cardProps = disabled
    ? {
        onClick: inactiveCardClick,
      }
    : {
        onClick: () => void 0,
      };

  const cardContent = (
    <Card className={classes.card} {...cardProps} {...rest}>
      <CardContent className={classes.cardContent}>
        <Grid className={classes.header}>
          {image ? (
            <img alt="" src={image} className={classes.icon} />
          ) : (
            <FontAwesomeIcon icon={icon} className={classes.icon} />
          )}
          <Typography className={classes.title} variant="body2" component="p">
            {title}
          </Typography>
        </Grid>
        {children && (
          <Typography
            className={classes.content}
            variant="body2"
            color="textSecondary"
            component="p"
            data-testid="linkcard-content"
          >
            {children}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
  return (
    <StyledGrid
      item
      className={classNames(classes.root, {
        [classes.disabled]: disabled,
      })}
    >
      {disabled || !to ? (
        cardContent
      ) : (
        <Link
          to={to}
          className={classes.link}
          target={target}
          onClick={onClick}
        >
          {cardContent}
        </Link>
      )}
    </StyledGrid>
  );
};
