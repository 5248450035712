import { Dealer } from '@mygrimme/types';
import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getDealers } from '../utils/ApiQuery';
import { loadingStatus } from './utils';

export const DEALERS_FEATURE_KEY = 'dealers';

export type DealersState = EntityState<Dealer> & {
  requestModifiers: { countryCode?: string; language?: string };
  loadingStatus: loadingStatus;
  error: string | null;
};

export const dealersAdapter = createEntityAdapter<Dealer>({
  selectId: (model) => model.Id,
});

const createDealerArgsAction =
  createAction<DealersState['requestModifiers']>('dealer/args');

export const fetchDealers = createAsyncThunk(
  'dealers/fetch',
  async (
    args: {
      countryCode: string;
      language: string;
      accessToken: string;
    },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(createDealerArgsAction(args));
    return getDealers(args.countryCode, args.language, args.accessToken);
  },
);

export const initialDealersState: DealersState = dealersAdapter.getInitialState(
  {
    loadingStatus: loadingStatus.NOT_LOADED,
    error: null,
    requestModifiers: {},
  },
);

export const dealersSlice = createSlice({
  name: DEALERS_FEATURE_KEY,
  initialState: initialDealersState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createDealerArgsAction, (state, action) => {
        state.requestModifiers = action.payload;
      })
      .addCase(fetchDealers.pending, (state: DealersState) => {
        state.loadingStatus = loadingStatus.LOADING;
      })
      .addCase(
        fetchDealers.fulfilled,
        (state: DealersState, action: PayloadAction<Dealer[]>) => {
          dealersAdapter.setAll(state, action.payload);
          state.loadingStatus = loadingStatus.LOADED;
        },
      )
      .addCase(fetchDealers.rejected, (state: DealersState, action) => {
        state.loadingStatus = loadingStatus.ERROR;
        state.error = action.error.message;
      });
  },
});

export const dealersReducer = dealersSlice.reducer;

export const dealerActions = dealersSlice.actions;

const { selectAll, selectEntities } = dealersAdapter.getSelectors();

export const getDealersState = (rootState: unknown): DealersState =>
  rootState[DEALERS_FEATURE_KEY];

export const selectAllDealers = createSelector(getDealersState, selectAll);

export const selectDealersEntities = createSelector(
  getDealersState,
  selectEntities,
);

export const isLoadingDealers = createSelector(
  getDealersState,
  (state) => state.loadingStatus === loadingStatus.LOADING,
);

export const areDealersLoaded = createSelector(
  getDealersState,
  (state) => state.loadingStatus === loadingStatus.LOADED,
);

export const selectDealersRequestModifiers = createSelector(
  getDealersState,
  (state) => state.requestModifiers,
);
