import { Button, TitleDivider } from '@grimme/components';
import { ArrowBig } from '@grimme/icons';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon } from '@mui/icons-material';
import { Container, Grid, Hidden, Typography } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { prepareFullHDImage } from '../../../utils/utils';
import { LoginButton } from '../../login-button';
import { Timeline } from '../timeline/timeline';
import { Root, classes } from './styles';
import { useFeatureFlag } from '../../../utils/feature-flag';
import { FEATURE_FLAG_GRID } from '../../../utils/feature-flag/flags';
import { getRegisterUrl } from '../../../utils/auth/register';

interface IBannerProps {
  cta_button_label?: string;
  cta_button_url?: string;
  features?: {
    title: string;
  }[];
  headline?: string;
  image?: string;
  subheadline?: string;
  video_hq?: string;
  video_lq?: string;
}

export const Banner = (props: IBannerProps) => {
  const {
    cta_button_label,
    features,
    headline,
    image,
    subheadline,
    video_hq,
    video_lq,
  } = props;

  const { t, i18n } = useTranslation();
  const insights = useAppInsightsContext();

  const backgroundClasses = classNames(
    classes.backgroundVideo,
    classes.backgroundOverlay,
  );
  const bannerHeight = window?.innerHeight || '100vh';
  const scrollOffset = window?.innerHeight ? window?.innerHeight - 54 : 0;
  const scrollText = t('myGRIMME_banner_scroll_down', 'Scroll down');

  const hasGridFlag = useFeatureFlag(FEATURE_FLAG_GRID, { email: undefined });

  const registerUrl = getRegisterUrl(i18n.language, hasGridFlag);

  const scrollDown = () =>
    window.scrollBy({
      top: scrollOffset,
      left: 0,
      behavior: 'smooth',
    });

  return (
    <Root className={classes.root}>
      <Hidden only="xs">
        <video
          autoPlay
          loop
          muted
          playsInline
          id="myVideo"
          className={classes.backgroundVideo}
          style={{ height: bannerHeight }}
          poster={prepareFullHDImage(image)}
        >
          <source src={video_hq} type="video/mp4" />
        </video>
      </Hidden>
      <Hidden smUp>
        <video
          autoPlay
          loop
          muted
          playsInline
          disablePictureInPicture
          id="myVideo"
          className={classes.backgroundVideo}
          style={{ height: bannerHeight }}
          poster={prepareFullHDImage(image)}
        >
          <source src={video_lq} type="video/mp4" />
        </video>
      </Hidden>
      <div className={backgroundClasses} style={{ height: bannerHeight }}></div>
      <Container
        maxWidth={false}
        className={classes.banner}
        style={{
          height: bannerHeight,
        }}
        disableGutters
      >
        <Container className={classes.fullHeight}>
          <Grid
            className={classes.fullHeight}
            container
            direction="row"
            justifyContent="space-between"
          >
            <Grid item xs={12} />
            <Grid container item xs={12} alignItems="center">
              <Grid item xs={12}>
                <Grid item sm={6}>
                  <Typography variant="h1">{headline}</Typography>
                  <TitleDivider />
                  <Typography
                    className={classes.bannerSubtitleRegular}
                    component="h2"
                    variant="h4"
                  >
                    {subheadline}
                  </Typography>
                  <Grid className={classes.buttons}>
                    <LoginButton
                      classes={classes.login}
                      endIcon={<ArrowBig className={classes.arrow} />}
                      modifier="dark"
                      variant="secondary"
                    />
                    <a
                      href={registerUrl}
                      title={cta_button_label}
                      className={classes.registerButtonWrapper}
                    >
                      <Button
                        endIcon={<ArrowBig className={classes.arrow} />}
                        modifier="light"
                        onClick={() => {
                          insights.trackEvent({ name: 'Started Registration' });
                        }}
                        variant="primary"
                      >
                        {cta_button_label}
                      </Button>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} alignContent="flex-end">
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                className={classes.arrowIndicatorRegular}
              >
                <div
                  aria-label={scrollText}
                  className={classes.buttonScroll}
                  onClick={scrollDown}
                  role="button"
                >
                  <KeyboardArrowDownRoundedIcon fontSize="large" />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Timeline features={features} />
    </Root>
  );
};
