import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Button, Slide, Slider } from '@grimme/components';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { MachineMapping } from '@mygrimme/types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TelemetryDataBySerial } from '../../utils/ApiQueryTypes';
import { ConnectivityApi } from '../../utils/connectivity-api';
import { getSortByTelemetryComparator } from '../../utils/machines';
import { RoutesPath } from '../../utils/routes';
import { AddButton } from '../add-button';
import { LinkCard } from '../link-card';
import { MachineCard } from './MachineCard';
import {
  classes,
  StyledAddMachineButton,
  StyledGrid,
} from './MachineCarousel.styles';

interface MachineCarouselProps {
  machineDetails?: MachineMapping[];
  telemetryData?: TelemetryDataBySerial;
}

const AddNewMachine = () => {
  const { t, i18n } = useTranslation();
  return (
    <StyledAddMachineButton data-testid="my-machines">
      <LinkCard
        icon={faPlus}
        title={t('myGRIMME_core_my_machines', 'Meine Maschinen')}
        to={`/${i18n.language}/machines`}
      />
    </StyledAddMachineButton>
  );
};

export const MachineCarousel = (props: MachineCarouselProps) => {
  const { machineDetails, telemetryData } = props;
  const insights = useAppInsightsContext();
  const { t, i18n } = useTranslation();
  const hasMachines = machineDetails && machineDetails?.length > 0;

  const trackingEvent = (name: string, target: string) => {
    return insights.trackEvent({
      name: name,
      properties: {
        target: target,
      },
    });
  };

  if (!hasMachines) return <AddNewMachine />;

  return (
    <>
      <Slider dots={false} modifier="dark">
        {machineDetails
          ?.sort(getSortByTelemetryComparator(telemetryData))
          .map((machine, index) => {
            const image = new ConnectivityApi().getMachineImageURLBySerial(
              machine?.MachineSerialNumber,
            );
            const link = `/${i18n.language}/${RoutesPath.machineDetails}?serial=${machine?.MachineSerialNumber}`;
            const isTelemetryActive = machine?.IsTelemetryActive;
            const lastOnlineAt =
              telemetryData?.[machine?.MachineSerialNumber]?.lastOnlineAt;
            const status =
              telemetryData?.[machine?.MachineSerialNumber]?.status;
            const timestamp =
              telemetryData?.[machine?.MachineSerialNumber]?.timestamp;

            return (
              <Slide href={link} key={index} type="custom">
                <MachineCard
                  image={image}
                  isTelemetryActive={isTelemetryActive}
                  key={index}
                  lastOnlineAt={lastOnlineAt}
                  name={machine?.Name}
                  status={status}
                  timestamp={timestamp}
                />
              </Slide>
            );
          })}
      </Slider>
      <StyledGrid className={classes.root}>
        <Link className={classes.link} to={`/${i18n.language}/machines`}>
          <Button
            modifier="dark"
            onClick={() => trackingEvent('Opened Link', 'machines list')}
            variant="secondary"
          >
            {t('myGRIMME_core_show_all', 'Alle Anzeigen')}
          </Button>
        </Link>
        <Link to={`/${i18n.language}/home/new`}>
          <AddButton />
        </Link>
      </StyledGrid>
    </>
  );
};
