import { IconDefinition, IconName } from '@fortawesome/pro-solid-svg-icons';

export interface IconKit {
  height: number;
  name: string;
  path: string;
  unicode: number;
  width: number;
}

// TODO: at some point it might be better to move these variables
// to env. files;
export const FONT_AWESOME_API_URL = 'https://api.fontawesome.com';
export const FONT_AWESOME_AUTHORIZATION_TOKEN =
  '5B8118E3-B3EC-4BAB-9B0E-E01347FD1E7C';
export const FONT_AWESOME_GRAPHQL_QUERY = `
  query icons {
    me {
      kit(token: "3eed72c166") {
        iconUploads {
          name
          path
          unicode
          version
          width
          height
        }
      }
    }
  }
`;

export const populateWithIcons = (iconData) => {
  const uploadedIcons: IconDefinition[] = [];
  iconData?.data?.data.me.kit.iconUploads.forEach(
    ({ name, width, height, path, unicode }: IconKit) => {
      uploadedIcons?.push({
        prefix: 'fak',
        iconName: `${name}` as IconName,
        icon: [
          parseInt(`${width}`),
          parseInt(`${height}`),
          [], // no ligatures
          unicode.toString(16),
          `${path}`,
        ],
      });
    },
  );
  return uploadedIcons;
};
