import {
  NotificationMessage,
  notificationsActions,
  notificationType,
} from '@grimme/components';
import {
  AllInOneProfileData,
  MachineMapping,
  ProfileWithoutMachineMappings,
  UpsertMachineResponseData,
} from '@mygrimme/types';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  getProfile,
  updateProfile as updateProfileCall,
} from '../utils/ApiQuery';
import { addMachine, deleteMachine, editMachineName } from './machines.slice';
import { loadingStatus } from './utils';
import { userGridApi } from '../utils/rest-apis/grid/api';

export const AUTH_FEATURE_KEY = 'auth';

/*
  tokenResponse expiry dates have been serialized to unix timestamp
  if the date values need to be read, use new Date(extResponse)
*/

export type AuthState = {
  error?: string;
  isLoadingUser: boolean;
  isUpdatingCompany: boolean;
  loadingStatus?: loadingStatus;
  user?: AllInOneProfileData;
};

// ------ NEW GRID2.0 API ---- //
export const fetchMe = createAsyncThunk(
  'auth/me',
  async ({ accessToken }: { accessToken: string }, { dispatch }) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const { data } = await userGridApi.me.currentUserControllerGet({
        headers,
      });

      const fullProfile: AllInOneProfileData = {
        Status: {
          AddressCompleted: !!data.company?.address,
          EmailVerified: data.emailVerified,
          AccountVerfified: data.accountStatus === 'active',
        },
        Companies: [
          {
            BusinessRelationId: data.company?.businessRelationId as string,
            Name: data.company?.name,
            FavoriteDealerBusinessRelationId: data.company
              ?.dealerBusinessRelationId as string,
            Street: data.company?.address?.street,
            ZipCode: data.company?.address?.zipCode,
            City: data.company?.address?.city,
            Country: data.company?.address?.countryCode,
          },
        ],
        Language: data.preferredLanguageCode,
        Permissions: undefined, // TODO: missing param
        Phone: data.phone,
        MobilePhone: undefined, // TODO: missing param
        CreatedDateTime: data.createAt,
        Email: data.email,
        FirstName: data.firstName,
        LastName: data.lastName,
      };
      return fullProfile;
    } catch (error) {
      dispatch(
        notificationsActions.addErrorNotification({
          // TODO: Component library should not enforce what keys can be used
          message:
            'myGRIMME_core_company_update_failure' as NotificationMessage,
          code: notificationType.DEFAULT,
        }),
      );
    }
  },
);

export const updateCompany = createAsyncThunk(
  'auth/updateCompany',
  async (
    {
      accessToken,
      payload: { companyName, favoriteDealerId },
    }: {
      accessToken: string;
      payload: { companyName?: string; favoriteDealerId?: string };
    },
    { dispatch },
  ) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      await userGridApi.me.currentUserControllerPatchCompany(
        {
          dealerBusinessRelationId: favoriteDealerId,
          name: companyName,
        },
        { headers },
      );
    } catch (error) {
      dispatch(
        notificationsActions.addErrorNotification({
          // TODO: Component library should not enforce what keys can be used
          message: 'myGRIMME_core_profile_fetch_failure' as NotificationMessage,
          code: notificationType.DEFAULT,
        }),
      );
    }
  },
);

// ------ OLD APIs ---- //
export const fetchProfile = createAsyncThunk(
  'auth/fetchProfile',
  async (
    args: { email: string; language: string; accessToken: string },
    { dispatch },
  ) => {
    try {
      const response = await getProfile(
        args.email,
        args.language,
        args.accessToken,
      );
      return response;
    } catch (error) {
      dispatch(
        notificationsActions.addErrorNotification({
          // TODO: Component library should not enforce what keys can be used
          message: 'myGRIMME_core_profile_fetch_failure' as NotificationMessage,
          code: notificationType.DEFAULT,
        }),
      );
    }
  },
);

export const updateProfile = createAsyncThunk(
  'auth/updateProfile',
  async (
    args: {
      email: string;
      data: ProfileWithoutMachineMappings;
      silent?: boolean;
      code?: notificationType;
      accessToken: string;
    },
    { dispatch },
  ) => {
    try {
      if (!args.silent) {
        dispatch(
          notificationsActions.addInfoNotification({
            message:
              'myGRIMME_core_account_form_pending' as NotificationMessage,
          }),
        );
      }
      const request = await updateProfileCall(
        args.email,
        args.data,
        args.accessToken,
      );
      if (!args.silent) {
        dispatch(
          notificationsActions.addSuccessNotification({
            message: 'myGRIMME_core_account_form_success',
            code: notificationType.DEFAULT,
          }),
        );
      }
      return request;
    } catch (error) {
      if (!args.silent) {
        dispatch(
          notificationsActions.addErrorNotification({
            message: 'myGRIMME_core_account_form_failure',
            code: notificationType.DEFAULT,
          }),
        );
      }
    }
  },
);

export const initialAuthState: AuthState = {
  error: undefined,
  isLoadingUser: false,
  isUpdatingCompany: false,
  loadingStatus: loadingStatus.NOT_LOADED,
  user: undefined,
};

export const authSlice = createSlice({
  name: AUTH_FEATURE_KEY,
  initialState: initialAuthState,
  reducers: {
    // ...
  },
  extraReducers: (builder) => {
    builder
      // ------ NEW GRID2.0 API ---- //
      .addCase(fetchMe.pending, (state: AuthState) => {
        state.isLoadingUser = true;
        state.loadingStatus = loadingStatus.LOADING;
        state.error = undefined;
      })
      .addCase(fetchMe.fulfilled, (state: AuthState, action) => {
        state.isLoadingUser = false;
        state.error = undefined;
        state.loadingStatus = loadingStatus.LOADED;
        state.user = action.payload;
      })
      .addCase(fetchMe.rejected, (state: AuthState, action) => {
        state.isLoadingUser = false;
        state.error = action.error.message;
        state.loadingStatus = loadingStatus.ERROR;
        state.user = undefined;
      })
      .addCase(updateCompany.pending, (state: AuthState) => {
        state.isUpdatingCompany = true;
      })
      .addCase(updateCompany.fulfilled, (state: AuthState, action) => {
        const userCompany = state.user?.Companies?.[0];

        if (!userCompany) {
          return;
        }

        const { companyName, favoriteDealerId } = action.meta.arg.payload;

        userCompany.Name = companyName || userCompany.Name;
        userCompany.FavoriteDealerBusinessRelationId =
          favoriteDealerId || userCompany.FavoriteDealerBusinessRelationId;

        state.isUpdatingCompany = false;
      })
      .addCase(updateCompany.rejected, (state: AuthState) => {
        state.isUpdatingCompany = false;
      })
      // ------ OLD APIs ---- //
      .addCase(fetchProfile.pending, (state: AuthState) => {
        state.isLoadingUser = true;
        state.loadingStatus = loadingStatus.LOADING;
        state.error = undefined;
      })
      .addCase(fetchProfile.fulfilled, (state: AuthState, action) => {
        state.isLoadingUser = false;
        state.error = undefined;
        state.loadingStatus = loadingStatus.LOADED;
        state.user = action.payload;
      })
      .addCase(fetchProfile.rejected, (state: AuthState, action) => {
        state.isLoadingUser = false;
        state.error = action.error.message;
        state.loadingStatus = loadingStatus.ERROR;
        state.user = undefined;
      })
      .addCase(updateProfile.pending, (state: AuthState, action) => {
        state.isLoadingUser = !action.meta.arg?.silent && true;
      })
      .addCase(updateProfile.fulfilled, (state: AuthState, action) => {
        state.isLoadingUser = false;
        state.user = action.payload;
      })
      .addCase(updateProfile.rejected, (state: AuthState, action) => {
        state.isLoadingUser = false;
        state.error = action.error.message;
      })
      .addCase(
        addMachine.fulfilled,
        (
          state: AuthState,
          action: PayloadAction<
            UpsertMachineResponseData | undefined,
            string,
            {
              arg: {
                email: string;
                mappingData: MachineMapping;
              };
              requestId: string;
            }
          >,
        ) => {
          if (state.user?.Companies?.[0]) {
            state.user.Companies[0].MachineMappings = action.payload?.map(
              (m) => ({
                IsTelemetryActive: m.IsTelemetryActive,
                MachineSerialNumber: m.MachineSerialNumber,
                ManufacturingYear: m.ManufacturingYear,
                Name: m.Name,
              }),
            );
          }
        },
      )
      .addCase(
        editMachineName.fulfilled,
        (
          state: AuthState,
          action: PayloadAction<
            MachineMapping | undefined,
            string,
            {
              arg: {
                email: string;
                serial: string;
                name: string;
                silent?: boolean | undefined;
                code?: notificationType | undefined;
              };
              requestId: string;
            }
          >,
        ) => {
          const editedMachine = action.payload;
          const machine = state.user?.Companies?.[0].MachineMappings?.find(
            (m) =>
              m?.MachineSerialNumber === editedMachine?.MachineSerialNumber,
          );
          // upsert
          if (machine) {
            machine.Name = editedMachine?.Name;
          } else {
            state.user?.Companies?.[0].MachineMappings?.push(
              editedMachine as MachineMapping,
            );
          }
        },
      )
      .addCase(deleteMachine.fulfilled, (state, action) => {
        state.user = action.payload;
      });
  },
});

export const authReducer = authSlice.reducer;

export const authActions = authSlice.actions;

export const getAuthState = (rootState: {
  [AUTH_FEATURE_KEY]: AuthState;
}): AuthState => rootState[AUTH_FEATURE_KEY];

export const isLoggingIn = createSelector(
  getAuthState,
  (state) => state.isLoadingUser,
);

export const isLoggedIn = createSelector(getAuthState, (state) =>
  Boolean(state.loadingStatus === loadingStatus.LOADED && Boolean(state.user)),
);

export const getAuthError = createSelector(
  getAuthState,
  (state) => state.error,
);

export const getUser = createSelector(getAuthState, (state) => state.user);

export const getUserMachines = createSelector(getUser, (state) =>
  state?.Companies?.reduce<MachineMapping[]>(
    (prev, cur) => [...prev, ...(cur.MachineMappings ?? [])],
    [],
  ),
);

export const getUserMachineBySerial = (serial: string) =>
  createSelector(getUser, getUserMachines, (_, machines) =>
    machines?.find((m) => m?.MachineSerialNumber === serial.toString()),
  );

export const getUserMachinesSerials = createSelector(
  getUser,
  getUserMachines,
  (_, machines) => machines?.map((m) => m.MachineSerialNumber),
);

export const getUserTelemetryMachinesSerials = createSelector(
  getUser,
  getUserMachines,
  (_, machines) =>
    machines
      ?.filter((m) => m.IsTelemetryActive)
      .map((m) => m.MachineSerialNumber),
);

export const selectIsUserLoaded = createSelector(
  getAuthState,
  (state) => state.loadingStatus === loadingStatus.LOADED,
);

export const selectIsUserLoading = createSelector(
  getAuthState,
  (state) => state.loadingStatus === loadingStatus.LOADING,
);

export const selectIsAuthApiMissing = createSelector(
  getAuthState,
  (state) => state.error === 'NetworkError',
);

export const selectIsUnauthorized = createSelector(
  getAuthState,
  (state) => state.error === 'Unauthorized Request',
);

export const selectFavoriteDealerId = createSelector(
  getAuthState,
  (state) => state.user?.Companies?.[0].FavoriteDealerBusinessRelationId,
);

export const selectIsCompanyUpdating = createSelector(
  getAuthState,
  (state) => state.isUpdatingCompany,
);
