import { useLocation } from 'react-router-dom';
import { environment } from '../../environments';

export const useQuery = () => new URLSearchParams(useLocation().search);

export const reformatDate = (date: string) => {
  const newDate = new Date(date);
  return newDate?.toLocaleTimeString('de', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const devLog = environment.production ? () => {} : console.log;

export const prepareFullHDImage = (url?: string) => {
  return url?.replace(
    'https://cdn.buttercms.com',
    'https://cdn.buttercms.com/resize=w:1920,h:1080,f:max,a:center',
  );
};

export const NON_BREAKING_WHITE_SPACE = '\xa0';

/**
Formats a price value to a currency string using the given locale.
@param args.price The price value to be formatted.
@param args.currency The currency code.
@param args.locale The locale.
@returns A string representing the formatted price in the specified currency and locale, or undefined if any of the input arguments is missing.
*/
export const formatPrice = (args: {
  price: number;
  currency: string;
  locale: string;
  style?: string;
}): string | undefined => {
  const { currency, locale, price, style = 'currency' } = args;
  if (!(currency && locale && price)) return undefined;
  return new Intl.NumberFormat(locale, {
    currency,
    style,
  })
    .format(price)
    .replace(NON_BREAKING_WHITE_SPACE, ' ');
};
