import {
  AllInOneProfileData,
  Dealer,
  DealersResponse,
  EditMachineNameRequestData,
  FakePermission,
  Geolocation,
  GSCSerialResponse,
  MachineConfiguration,
  MachineDocumentListResponse,
  MachineMapping,
  ProfileWithoutMachineMappings,
  SerialMachineData,
  SortCropsBody,
  UpdateProfileResponseData,
  UpsertMachineRequestData,
  UpsertMachineResponseData,
} from '@mygrimme/types';
import { AxiosError } from 'axios';
import { Account, PostAccount, User } from '../pages/admin/utils/utils'; //TODO: once types are updated, replace it
import { CropFormData } from '../pages/crops/utils';
import { GrimmeApiCrop } from '../pages/crops/utils/types';
import { GetWearPartsResponse } from '../pages/machine-details/hooks/types';
import { LocalMachine } from '../redux/utils';
import {
  ApiCalls,
  APIError,
  CacheInvalidationMap,
  CallApiProps,
  CCITokenResponse,
  ErrorBody,
  MachineConfigurationObject,
  NetworkError,
  TelemetryDataBySerial,
} from './ApiQueryTypes';
import { getCachedAxiosInstance } from './axios';
import { environmentConstants } from './config';
import { DEFAULT_COUNTRY_CODE, DEFAULT_DEALER, DEFAULT_LOCALE } from './consts';

const endpointsCacheConfig: CacheInvalidationMap = {
  addMachine: {
    invalidates: ['getMachines', 'getTelemetryData'],
  },
  editMachineName: { invalidates: ['getMachines', 'getTelemetryData'] },
  deleteMachineQuery: { invalidates: ['getMachines', 'getTelemetryData'] },
  getMachines: {
    cache: true,
  },
  getTelemetryData: {
    cache: true,
  },
};

export const createApiUrl = (path: string) => {
  const apiUrl = environmentConstants.apiUrl;
  path = path[0] === '/' ? path : `/${path}`;
  return new URL(`${apiUrl}${path}`);
};

export const callApi = async <Result, Body = undefined>({
  id,
  endpoint,
  language = 'de',
  method = 'get',
  body = undefined,
  responseType = 'json',
  accessToken,
}: CallApiProps<Body>) => {
  const url = new URL(endpoint.toString());
  const axios = getCachedAxiosInstance();
  if (language) {
    url.searchParams.set('language', language);
  }

  const headers: Record<string, string> = {
    'Content-type': 'application/json',
  };

  try {
    if (!accessToken) {
      throw new Error('No access token');
    }
    headers['x-access-token'] = accessToken;
    headers['Authorization'] = `Bearer ${accessToken}`;
  } catch (error) {
    console.error(typeof error, { error });
  }

  const { invalidates, cache } = endpointsCacheConfig[id] ?? {};
  let response;

  if (axios) {
    try {
      response = await axios({
        id,
        url: url.toString(),
        method,
        headers,
        cache: cache ? undefined : false,
        data: JSON.stringify(body),
        responseType: responseType,
      });

      if (invalidates) {
        Promise.all(invalidates.map((id) => axios?.storage.remove(id)));
      }
    } catch (error) {
      const axiosError = error as AxiosError<AxiosError>;
      if (axiosError.response?.status && axiosError.response?.status > 400) {
        throw new APIError(axiosError.response.data);
      } else {
        throw new NetworkError(error);
      }
    }
  }

  // no content
  if (response?.status === 204) {
    return;
  }

  return response?.data as Result;
};

// body is extended since compiler thinks its React due to tsx
export const callApiBinary = async <Body,>({
  endpoint,
  method = 'get',
  body,
  accessToken,
}: Omit<CallApiProps<Body>, 'language'>): Promise<string | undefined> => {
  const url = new URL(endpoint.toString());

  const headers: HeadersInit | undefined = {
    'content-type': 'application/json',
  };
  try {
    if (!accessToken) {
      throw new Error('No access token');
    }
    headers['x-access-token'] = accessToken;
    headers['Authorization'] = `Bearer ${accessToken}`;
  } catch (error) {
    console.error(typeof error, { error });
  }

  const response = await fetch(url, {
    headers,
    method,
    body: JSON.stringify(body),
  });
  const resBody = await (response.blob() as Promise<Blob | MediaSource>);

  if (response.status >= 400) {
    throw new APIError(resBody as ErrorBody);
  }

  try {
    return URL.createObjectURL(resBody);
  } catch (error) {
    console.error({ error });
  }
};

export const getDocumentsBySerial: ApiCalls['getDocumentsBySerial'] = async (
  serial: string,
  language: string,
  accessToken: string,
): Promise<{ serial: string; documents: MachineDocumentListResponse }> => {
  const endpoint = createApiUrl(
    `machines/documents?serial=${serial}&language=${language}`,
  );
  const documents = await callApi<MachineDocumentListResponse>({
    id: 'getDocumentsBySerial',
    endpoint,
    language,
    accessToken,
  });
  return {
    serial,
    documents: documents || [],
  };
};

const apiCalls: ApiCalls = {
  /** @deprecated */
  getProfile: async (
    email: string,
    language: string,
    accessToken: string,
  ): Promise<AllInOneProfileData | undefined> => {
    const endpoint = createApiUrl(`profile?email=${email}`);
    return callApi<AllInOneProfileData>({
      id: 'getProfile',
      endpoint,
      language,
      accessToken,
    });
  },
  updateProfile: async (
    email: string,
    data: ProfileWithoutMachineMappings,
    accessToken: string,
  ): Promise<UpdateProfileResponseData | undefined> => {
    const endpoint = createApiUrl(`profile/${email}`);
    return await callApi<
      UpdateProfileResponseData,
      ProfileWithoutMachineMappings
    >({
      id: 'updateProfile',
      endpoint,
      method: 'post',
      body: data,
      accessToken,
    });
  },

  getAccounts: async (id: string, language: string, accessToken: string) => {
    const endpoint = createApiUrl(`accounts?id=${id}`);
    return callApi<Account[]>({
      id: 'getAccounts',
      endpoint,
      language,
      accessToken,
    });
  },
  getAccount: async (email: string, language: string, accessToken: string) => {
    const endpoint = createApiUrl(`accounts/${email}`);
    return callApi<User>({ id: 'getAccount', endpoint, language, accessToken });
  },
  deleteAccount: async (email: string, accessToken: string) => {
    const endpoint = createApiUrl(`accounts/${email}`);
    return callApi<User>({
      id: 'getAccount',
      endpoint,
      method: 'delete',
      accessToken,
    });
  },
  createAccount: async (
    body: PostAccount,
    language: string,
    accessToken: string,
  ) => {
    const endpoint = createApiUrl(`accounts`);
    return callApi<User, PostAccount>({
      id: 'createAccount',
      endpoint,
      language,
      method: 'post',
      body,
      accessToken,
    });
  },
  updateAccount: async (
    email: string,
    permissions: FakePermission[],
    language: string,
    accessToken: string,
  ): Promise<AllInOneProfileData | undefined> => {
    const endpoint = createApiUrl(`accounts/${email}`);
    return callApi({
      id: 'updateAccount',
      endpoint,
      language,
      method: 'put',
      body: permissions,
      accessToken,
    });
  },

  createCrop: async (accessToken: string | undefined, body: CropFormData) => {
    const endpoint = createApiUrl('crops');
    return callApi({
      id: 'createCrop',
      endpoint,
      method: 'post',
      body,
      accessToken,
    });
  },

  deleteCrop: async (
    accessToken: string | undefined,
    cropType: number,
    cropVarietyId: number,
  ) => {
    const endpoint = createApiUrl(
      `crops?cropType=${cropType}&cropVarietyId=${cropVarietyId}`,
    );
    return callApi({
      id: 'deleteCrop',
      endpoint,
      method: 'delete',
      accessToken,
    });
  },

  updateCrop: async (accessToken: string | undefined, body: CropFormData) => {
    const endpoint = createApiUrl('crops');
    return callApi({
      id: 'updateCrop',
      endpoint,
      method: 'put',
      body,
      accessToken,
    });
  },

  getGeolocation: async (
    language: string,
  ): Promise<Geolocation | undefined> => {
    const threeDays = 1000 * 60 * 60 * 24 * 3;
    const curLocation = localStorage.getItem('geolocation');
    const dateLocation = localStorage.getItem('geolocation-date');

    if (curLocation && Number(dateLocation) + threeDays > Date.now()) {
      return JSON.parse(curLocation);
    }

    const endpoint = createApiUrl('geolocation');
    const location = await callApi<Geolocation>({
      id: 'getGeolocation',
      endpoint,
      language,
    });

    localStorage.setItem('geolocation', JSON.stringify(location));
    localStorage.setItem('geolocation-date', Date.now().toFixed(0));

    return location;
  },

  getMachines: async (
    serials: string[],
    language: string,
    accessToken: string,
  ) => {
    const endpoint = createApiUrl('machines');
    for (const serial of serials) {
      endpoint.searchParams.append('serials', serial);
    }
    const machines = await callApi<SerialMachineData[]>({
      id: 'getMachines',
      endpoint,
      language,
      accessToken,
    });
    return machines || [];
  },
  getDocumentsBySerial,
  getMachineBySerial: async (
    serial: string,
    language: string,
    accessToken: string,
  ): Promise<LocalMachine> => {
    const endpoint = createApiUrl(`machines/${serial}`);
    const machine = await callApi<SerialMachineData>({
      id: 'getMachineBySerial',
      endpoint,
      language,
      accessToken,
    });

    let documentsList: MachineDocumentListResponse;

    try {
      documentsList = (
        await getDocumentsBySerial(serial, language, accessToken)
      ).documents;
    } catch {
      documentsList = [];
    }

    const documentsByLanguage = {
      [language]: documentsList,
    };
    return {
      ...machine,
      documents: documentsByLanguage,
    } as LocalMachine;
  },
  getMachineByGSCSerial: async (
    serial: string,
    language: string,
    accessToken: string,
  ): Promise<GSCSerialResponse | undefined> => {
    const endpoint = createApiUrl(`machines/${serial}/gsc-serial-number`);
    return await callApi<GSCSerialResponse>({
      id: 'getMachineByGSCSerial',
      endpoint,
      language,
      accessToken,
    });
  },
  getMachineConfigurationBySerial: async (
    serial: string,
    language: string,
    accessToken: string,
  ): Promise<MachineConfigurationObject> => {
    const endpoint = createApiUrl(`machines/${serial}/configuration`);
    const configuration = await callApi<MachineConfiguration[]>({
      id: 'getMachineConfigurationBySerial',
      endpoint,
      language,
      accessToken,
    });
    return { serial, configuration: configuration || [] };
  },
  addMachine: async (
    email: string,
    machine: UpsertMachineRequestData,
    accessToken: string,
  ): Promise<UpsertMachineResponseData | undefined> => {
    const endpoint = createApiUrl(`machines/${email}`);

    return callApi<UpsertMachineResponseData, UpsertMachineRequestData>({
      id: 'addMachine',
      endpoint,
      method: 'post',
      body: machine,
      accessToken,
    });
  },
  editMachineName: async (
    email: string,
    serial: string,
    name: string,
    accessToken: string,
  ): Promise<MachineMapping | undefined> => {
    const endpoint = createApiUrl(`machines/name/${email}`);
    const requestData = {
      MachineSerialNumber: serial,
      Name: name,
    };

    return callApi<MachineMapping, EditMachineNameRequestData>({
      id: 'editMachineName',
      endpoint,
      method: 'put',
      body: requestData,
      accessToken,
    });
  },
  deleteMachineQuery: async (
    email: string,
    serial: string,
    language: string,
    accessToken: string,
  ) => {
    const endpoint = createApiUrl(`machines/${email}/${serial}`);

    return callApi<AllInOneProfileData>({
      id: 'deleteMachineQuery',
      endpoint,
      language,
      method: 'delete',
      accessToken,
    });
  },
  downloadDocument: async (
    docId: string,
    encodedDocumentHash: string,
    filename = 'daten.pdf',
    accessToken: string,
  ) => {
    const endpoint = createApiUrl(
      `machines/download?docId=${docId}&encodedDocumentHash=${encodedDocumentHash}&filename=${filename}`,
    );
    const objectUrl = await callApiBinary({
      id: 'downloadDocument',
      endpoint,
      accessToken,
    });

    // create in-memory a tag and simulate click to download
    const link = document.createElement('a');
    link.href = objectUrl as string;
    link.download = filename;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  },
  // TODO: deprecate
  getDealers: async (
    countryCode = 'DE',
    language = 'de',
    accessToken: string,
  ) => {
    const endpoint = createApiUrl('dealers');
    // language is appended in callApi
    endpoint.searchParams.append('countryCode', countryCode);
    const dealers =
      (await callApi<DealersResponse>({
        id: 'getDealers',
        endpoint,
        language,
        accessToken,
      })) || [];
    if (dealers?.length === 0) {
      dealers.push(DEFAULT_DEALER);
    }
    return dealers;
  },
  getDealersV2: async (
    countryCode = DEFAULT_COUNTRY_CODE,
    language = DEFAULT_LOCALE,
    accessToken: string,
  ) => {
    const endpoint = createApiUrl('dealers/v2');
    // language is appended in callApi
    endpoint.searchParams.append('countryCode', countryCode);
    const dealers =
      (await callApi<DealersResponse>({
        id: 'getDealersV2',
        endpoint,
        language,
        accessToken,
      })) || [];
    if (dealers?.length === 0) {
      dealers.push(DEFAULT_DEALER);
    }
    return dealers;
  },
  getDealerById: async (id: string, accessToken: string) => {
    const endpoint = createApiUrl(`dealers/${id}`);
    const dealer = await callApi<Dealer>({
      id: 'getDealerById',
      endpoint,
      accessToken,
    });
    return dealer;
  },

  getPermissions: async (language: string, accessToken: string) => {
    const endpoint = createApiUrl('permissions');
    const permissions = await callApi<FakePermission[]>({
      id: 'getPermissions',
      endpoint,
      language,
      accessToken,
    });
    return permissions || [];
  },

  getCrops: async (accessToken: string, bussinessRelationId: string) => {
    const endpoint = createApiUrl(
      `crops?businessRelationId=${bussinessRelationId}`,
    );
    const crops = await callApi<GrimmeApiCrop[]>({
      id: 'getCrops',
      endpoint,
      accessToken,
    });
    return crops || [];
  },

  sortCrop: async (
    accessToken: string,
    body: SortCropsBody,
    cropType: number,
  ) => {
    const endpoint = createApiUrl(`/crops/sort/${cropType}`);
    return callApi({
      id: 'sortCrop',
      endpoint,
      method: 'put',
      body,
      accessToken,
    });
  },

  getCCIAuthToken: async (sessionCode: string, accessToken: string) => {
    const endpoint = createApiUrl(`cci/token?sessionCode=${sessionCode}`);
    return callApi<CCITokenResponse>({
      id: 'getCCIAuthToken',
      endpoint,
      accessToken,
    });
  },

  getTelemetryData: async (
    serials: string[],
    accessToken: string,
    country?: string,
  ): Promise<TelemetryDataBySerial | undefined> => {
    const endpoint = createApiUrl('telemetry');
    serials?.forEach((serial) => {
      endpoint.searchParams.append('serials', serial);
    });
    if (country) {
      endpoint.searchParams.append('country', country);
    }
    return callApi<TelemetryDataBySerial>({
      id: 'getTelemetryData',
      endpoint,
      accessToken,
    });
  },

  getWearParts: async (
    accessToken: string,
    serialnumber: string,
    language: string,
  ) => {
    const endpoint = createApiUrl(
      `/wear-parts/${serialnumber}?language=${language}`,
    );

    return callApi<GetWearPartsResponse>({
      id: 'getWearParts',
      endpoint,
      language: language,
      accessToken,
    });
  },

  getWearPartsExcel: async (
    accessToken: string,
    serialnumber: string,
    language: string,
  ) => {
    const endpoint = createApiUrl(
      `/wear-parts/${serialnumber}/excel?language=${language}`,
    );

    return callApi<Blob>({
      id: 'getWearPartsExcel',
      endpoint,
      language,
      responseType: 'blob',
      accessToken,
    });
  },
};

export const {
  addMachine,
  createCrop,
  createAccount,
  deleteAccount,
  deleteCrop,
  deleteMachineQuery,
  downloadDocument,
  editMachineName,
  getAccount,
  getAccounts,
  getCCIAuthToken,
  getCrops,
  getDealerById,
  getDealers,
  getDealersV2,
  getGeolocation,
  getMachineByGSCSerial,
  getMachineBySerial,
  getMachineConfigurationBySerial,
  getMachines,
  getPermissions,
  getProfile,
  getTelemetryData,
  getWearParts,
  getWearPartsExcel,
  sortCrop,
  updateCrop,
  updateAccount,
  updateProfile,
} = apiCalls;
