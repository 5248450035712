import * as React from 'react';

const ShowFooterValueContext = React.createContext<boolean>(true);

const ShowFooterSetterContext = React.createContext<
  React.Dispatch<React.SetStateAction<boolean>>
>(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
);

export const useShowFooterValue = () => {
  return React.useContext(ShowFooterValueContext);
};

export const useShowFooterSetter = () => {
  return React.useContext(ShowFooterSetterContext);
};

export const ShowFooterProvider = (props) => {
  const [showFooter, setShowFooter] = React.useState(true);
  return (
    <ShowFooterSetterContext.Provider value={setShowFooter}>
      <ShowFooterValueContext.Provider value={showFooter}>
        {props.children}
      </ShowFooterValueContext.Provider>
    </ShowFooterSetterContext.Provider>
  );
};
