import { Dialog } from '@grimme/components';
import InfoIcon from '@mui/icons-material/Info';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IMaintenanceDialogProps {
  onConfirm?: () => void;
  open: boolean;
}

export const MaintenanceDialog = (props: IMaintenanceDialogProps) => {
  const { open, onConfirm } = props;
  const { t } = useTranslation();

  const confirmLabel = t(
    'myGRIMME_core_under_maintenance_info_confirm',
    'Verstanden',
  );
  const title = t(
    'myGRIMME_core_under_maintenance_info_headline',
    'Information',
  );
  const paragraph = t(
    'myGRIMME_core_under_maintenance_info_text',
    'Aufgrund dringender Wartungsarbeiten kann es derzeit zu einer eingeschränkten Verfügbarkeit einiger Funktionen in myGRIMME kommen.',
  );

  return (
    <Dialog
      labelConfirm={confirmLabel}
      icon={<InfoIcon color="primary" fontSize="large" />}
      onConfirm={onConfirm}
      open={open}
      title={title}
    >
      <Typography component="div" variant="body1">
        {paragraph}
      </Typography>
    </Dialog>
  );
};
