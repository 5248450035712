import * as React from 'react';
import { environment } from '../../environments/environment';

const processConfiguration: Partial<typeof environment> = {};

if (process.env.NODE_ENV) {
  processConfiguration.production = process.env.NODE_ENV === 'production';
}

if (process.env.API_URL) {
  processConfiguration.apiUrl = process.env.API_URL;
}

export const environmentConstants = {
  ...environment,
  ...processConfiguration,
  defaultLanguage: 'de',
  contactEmail: 'mygrimme@grimme.de',
};

const ConfigurationContext = React.createContext(environmentConstants);

export const useConfiguration = () => React.useContext(ConfigurationContext);
