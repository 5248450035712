import { Grid, generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses('FiftyFifty', [
  'root',
  'cart',
  'container',
  'dataContainer',
  'divider',
  'inverse',
  'iconContainer',
  'iconRegular',
  'imageContainer',
  'orderRegular',
  'orderInverted',
  'overlay',
  'textContainer',
  'textContainerInverted',
  'title',
]);

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.grey[200],
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(5),
    },
  },

  [`& .${classes.container}`]: {
    display: 'flex',
    minHeight: '65vh',
    height: '600px',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      minHeight: 'auto',
    },
  },

  [`& .${classes.orderRegular}`]: {
    width: 'calc(50% - 150px)',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
    },
  },

  [`& .${classes.orderInverted}`]: {
    width: 'calc(50% - 150px)',
    display: 'flex',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
    },
  },

  [`& .${classes.inverse}`]: {
    flexDirection: 'row-reverse',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      flexDirection: 'column',
    },
  },

  [`& .${classes.overlay}`]: {
    backgroundBlendMode: 'multiply',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },

  [`& .${classes.textContainer}`]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginRight: 'auto',
      maxWidth: '600px',
      paddingTop: 0,
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },

  [`& .${classes.textContainerInverted}`]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginRight: 'auto',
      maxWidth: '600px',
      paddingTop: 0,
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },

  [`& .${classes.iconContainer}`]: {
    background: theme.palette.common.white,
    width: '80px',
    height: '80px',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    boxShadow: '2px 2px 10px #00000080',
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      display: 'none',
    },
  },

  [`& .${classes.iconRegular}`]: {
    width: '40%',
  },

  [`& .${classes.imageContainer}`]: {
    width: 'calc(50% + 150px)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    boxShadow: '0px 0px 4px 2px #00000060',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      width: '100%',
      height: '240px',
    },
  },

  [`& .${classes.dataContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    width: '490px',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      width: '100%',
    },
  },

  [`& .${classes.cart}`]: {
    position: 'relative',
    right: '2px',
    width: '40px',
  },

  [`& .${classes.title}`]: {
    wordBreak: 'break-word',
  },

  [`& .${classes.divider}`]: {},
}));
