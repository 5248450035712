import {
  ComponentsConfigProvider,
  GrimmeComponentsProvider,
  LocalizationProvider,
} from '@grimme/components';
import { AnalyticsProvider, useUserCentricsConsent } from '@grimme/gdap';
import { useSession } from '@grimme/next-grimme-auth';
import theme from '@grimme/theme';
import {
  CssBaseline,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import { History, createBrowserHistory } from 'history';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider as ReduxProvider, useDispatch } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { environment } from '../../environments/environment';
import { defaultLocale } from '../../gen';
import { IndexAppProps } from '../app';
import store, { persistor } from '../redux';
import { fetchMe, fetchProfile } from '../redux/auth.slice';
import { LANGUAGE_CODE } from '../utils/i18n';
import { LanguageProvider } from '../utils/language';
import { BreadcrumbsProvider } from '../utils/useBreadcrumbs';
import { ShowContactProvider } from '../utils/useShowContact';
import { ShowFooterProvider } from '../utils/useShowFooter';
import { FEATURE_FLAG_GRID } from '~/app/utils/feature-flag/flags';
import { useFeatureFlag } from '~/app/utils/feature-flag';
import { Action } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

//TODO: Find a way to include it in the theme;
declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const generateClassName = createGenerateClassName({
  productionPrefix: 'mygrimme-core',
});

interface ProviderProps {
  children: React.ReactNode;
  pageProps: IndexAppProps;
}

const ProviderWrapper = ({ children, pageProps }: ProviderProps) => {
  const [history, setHistory] = useState<History<unknown>>();
  const isAnalyticsConsentGiven = useUserCentricsConsent(
    'Azure Application Insights',
  );

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const _history = createBrowserHistory();
    setHistory(_history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data } = useSession();

  const username = useMemoCompare(
    data?.user?.name,
    (prev, next) => prev === next,
  );

  if (!history) {
    return null;
  }

  const { commitHash, insightsKey, projectName, appVersion } = environment;

  if (!commitHash || !insightsKey || !projectName || !appVersion) {
    const notSet: string[] = [];
    const envs = { commitHash, projectName, appVersion, insightsKey };
    for (const name in envs) {
      if (!envs[name as keyof typeof envs]) {
        notSet.push(name);
      }
    }
    throw new Error(
      `The following environment variables have not been set: ${notSet.join(
        ', ',
      )}`,
    );
  }

  return (
    <ComponentsConfigProvider t={t} language={i18n.language}>
      <ReduxProvider store={store}>
        <AnalyticsProvider
          instrumentationKey={insightsKey}
          isConsentGiven={isAnalyticsConsentGiven}
          defaultEnvelopeData={{
            project: projectName,
            commit: commitHash,
            version: appVersion,
          }}
          username={username}
        >
          <AuthProvider>
            <PersistGate loading={null} persistor={persistor}>
              <React.StrictMode>
                <StylesProvider generateClassName={generateClassName}>
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <Typography component="div">
                        <Router history={history}>
                          <LanguageProvider
                            locale={pageProps.locale || defaultLocale}
                          >
                            <GrimmeComponentsProvider>
                              <LocalizationProvider>
                                <ShowContactProvider>
                                  <ShowFooterProvider>
                                    <BreadcrumbsProvider>
                                      {children}
                                    </BreadcrumbsProvider>
                                  </ShowFooterProvider>
                                </ShowContactProvider>
                              </LocalizationProvider>
                            </GrimmeComponentsProvider>
                          </LanguageProvider>
                        </Router>
                      </Typography>
                    </ThemeProvider>
                  </StyledEngineProvider>
                </StylesProvider>
              </React.StrictMode>
            </PersistGate>
          </AuthProvider>
        </AnalyticsProvider>
      </ReduxProvider>
    </ComponentsConfigProvider>
  );
};

export default ProviderWrapper;

function AuthProvider({ children }: { children: React.ReactNode }) {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { language } = i18n ?? { language: LANGUAGE_CODE };
  const { data: sessionData, status } = useSession();

  const jwtPayload =
    sessionData?.accessToken &&
    (jwtDecode(sessionData?.accessToken) as { email: string; emails: string });

  const hasGridFlag = useFeatureFlag(FEATURE_FLAG_GRID, {
    email: jwtPayload && (jwtPayload.email ?? jwtPayload.emails?.[0]),
  });

  useEffect(() => {
    if (
      status !== 'authenticated' ||
      !sessionData ||
      hasGridFlag === undefined
    ) {
      return;
    }

    dispatch(
      hasGridFlag
        ? fetchMe({ accessToken: sessionData.accessToken })
        : (fetchProfile({
            email:
              sessionData.user.email ??
              (jwtPayload && (jwtPayload.email ?? jwtPayload.emails?.[0])),
            language,
            accessToken: sessionData.accessToken,
          }) as unknown as Action),
    );

    // session in dependancy array causes page to rerender
    // too often without need;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, language, status, hasGridFlag]);

  return children;
}

function useMemoCompare<T>(next: T, compare: (prev?: T, next?: T) => boolean) {
  // Ref for storing previous value
  const previousRef = useRef<T>();
  const previous = previousRef.current;
  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = compare(previous, next);
  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });
  // Finally, if equal then return the previous value
  return isEqual ? previous : next;
}
