import { environment } from '~/environments';
import { CurrentUserApi, CurrentUserMachinesApi } from '~/gen/grid-user';
import { MachinesApi } from '~/gen/grid-admin';
import axios from 'axios';

const basePath = environment.gridUrl;

export const userGridApi = {
  me: new CurrentUserApi(undefined, basePath, axios),
  machinesApi: new CurrentUserMachinesApi(undefined, basePath, axios),
};

export const adminGridApi = {
  machines: new MachinesApi(undefined, basePath),
};
