// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://fc4d4a87f9f649d0b7b79ccd7753af9c@o1318498.ingest.sentry.io/6573062',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.0,
  enabled: false,
  // process.env.NEXT_PUBLIC_APP_ENV !== 'tst' &&
  // process.env.NODE_ENV !== 'development',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
