import Butter from 'buttercms';
import { environment } from '../../../environments/environment';

const butter = Butter(environment?.cmsAuthToken as string);

export const getContent = async (
  locale: string,
  preview: boolean,
  keys: string[],
  levels = 3,
) => {
  const butterParams = {
    locale: locale,
    preview: preview ? 1 : 0,
    levels,
  };
  const content = await butter.content.retrieve(keys, butterParams);
  return content;
};
