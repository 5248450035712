import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useCallback } from 'react';

interface ITrackEvent {
  <T extends Record<string, unknown>>(eventName: string, properties?: T): void;
}

export const useGenericTrackingEvent = (): ITrackEvent => {
  const insights = useAppInsightsContext();
  const trackEvent: ITrackEvent = useCallback(
    <T extends Record<string, unknown>>(eventName: string, properties?: T) => {
      insights.trackEvent({ name: eventName, properties });
    },
    [insights],
  );
  return trackEvent;
};
