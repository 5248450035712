import { TComponentData } from '@grimme/buttery';
import { environment } from '../../../environments/';

export enum ButterContent {
  CROP_COLORS = 'cropvarietycolors',
  CROP_TYPES = 'croptypes',
}

export enum ButterPages {
  CONTACT_ASSISTANT_ICONS = 'contact-assistant-icons',
}

export type ButterData = {
  fields?: {
    body?: {
      fields?: {
        cta_button_label?: string;
        cta_button_url?: string;
        features?: {
          title: string;
        }[];
        headline?: string;
        image?: string;
        subheadline?: string;
        video_hq?: string;
        video_lq?: string;
      };
    }[];
    seo: {
      title?: string;
      description?: string;
    };
    buttery_components: TComponentData[];
  };
};

export const generateButterSlug = (
  language: string,
  slug: string,
  preview: string | null,
) => {
  const url = new URL(`https://api.buttercms.com/v2/pages/*/${slug}`);
  url.searchParams.set('locale', language);
  url.searchParams.set('auth_token', environment.cmsAuthToken ?? '');
  if (preview) {
    url.searchParams.set('preview', preview);
  }
  return url;
};

export const generateButterContentSlug = (language: string, slug: string) => {
  const url = new URL(`https://api.buttercms.com/v2/content/${slug}`);
  url.searchParams.set('locale', language);
  url.searchParams.set('auth_token', environment.cmsAuthToken ?? '');
  return url;
};
