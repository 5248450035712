import {
  TComponentData,
  extractIconData,
  populateAdditionalData,
} from '@grimme/buttery';
import { useEffect, useState } from 'react';
import { ButterData, generateButterSlug } from '../../app/utils';
import { getIcons } from '../../app/utils/font-awesome-icons';
import { environment } from '../../environments/';

export const fetchDataFromButter = async (url: string, signal: AbortSignal) => {
  const result = await fetch(url, { signal });
  const response = await result.json();
  return response;
};

export const processIconData = (buttery_data: TComponentData[]) => {
  const iconData = extractIconData(buttery_data);
  getIcons(...iconData);
};

export const processButteryData = async (
  buttery_data: TComponentData[],
  language: string,
  preview: boolean,
) => {
  const additionalData = await populateAdditionalData(
    buttery_data,
    language,
    preview,
    environment.cmsAuthToken,
  );

  processIconData(buttery_data);

  return additionalData;
};

export const useFetchAndProcessButterData = (
  language: string,
  slug: string,
  preview: boolean,
) => {
  const [data, setData] = useState<ButterData>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAndProcessData = async (controller: AbortController) => {
    setLoading(true);
    try {
      const url = generateButterSlug(language, slug, preview).toString();
      const butterData = await fetchDataFromButter(url, controller.signal);
      const processedButteryData = await processButteryData(
        butterData.data.fields.buttery_components,
        language,
        preview,
      );
      setData({
        ...butterData.data,
        fields: {
          ...butterData.data.fields,
          buttery_components: processedButteryData,
        },
      });
    } catch (e: any) {
      if (e.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        setError(e);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchAndProcessData(controller);
    return () => {
      controller.abort();
    };
  }, [language, preview]);

  return { data, loading, error };
};
