/* tslint:disable */
/* eslint-disable */
/**
 * User Facing | GRID API
 * This is the user facing API for the GRIMME Identity Platform.       It provides the necessary endpoints for the user to interact with the platform.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: digihub@grimme.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface CompanyAddressDto
 */
export interface CompanyAddressDto {
    /**
     * The company address city.
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'city': string;
    /**
     * The company address country code.
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'countryCode': string;
    /**
     * The company address street.
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'street': string;
    /**
     * The company address zip code.
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'zipCode': string;
}
/**
 * 
 * @export
 * @interface CompanyRoleDto
 */
export interface CompanyRoleDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyRoleDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRoleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRoleDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRoleDto
     */
    'publicNameTranslationKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyRoleDto
     */
    'publicDescriptionTranslationKey'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyRolesResponseDto
 */
export interface CompanyRolesResponseDto {
    /**
     * 
     * @type {Array<CompanyRoleDto>}
     * @memberof CompanyRolesResponseDto
     */
    'data': Array<CompanyRoleDto>;
}
/**
 * 
 * @export
 * @interface CompanyUserDto
 */
export interface CompanyUserDto {
    /**
     * The user\'s unique identifier.
     * @type {string}
     * @memberof CompanyUserDto
     */
    'id': string;
    /**
     * The user\'s email.
     * @type {string}
     * @memberof CompanyUserDto
     */
    'email': string;
    /**
     * The user\'s first name.
     * @type {string}
     * @memberof CompanyUserDto
     */
    'firstName': string;
    /**
     * The user\'s last name.
     * @type {string}
     * @memberof CompanyUserDto
     */
    'lastName': string;
    /**
     * The user\'s phone number.
     * @type {string}
     * @memberof CompanyUserDto
     */
    'phone'?: string | null;
    /**
     * The user\'s preferred language code.
     * @type {string}
     * @memberof CompanyUserDto
     */
    'preferredLanguageCode': string;
    /**
     * The ID that the user\'s identity provider uses.
     * @type {string}
     * @memberof CompanyUserDto
     */
    'identityProviderId': string;
    /**
     * The user is verified or not.
     * @type {boolean}
     * @memberof CompanyUserDto
     */
    'emailVerified': boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserDto
     */
    'accountStatus': CompanyUserDtoAccountStatusEnum;
    /**
     * Datetime when account was created
     * @type {string}
     * @memberof CompanyUserDto
     */
    'createAt': string;
}

export const CompanyUserDtoAccountStatusEnum = {
    Pending: 'pending',
    Active: 'active',
    Declined: 'declined',
    Invited: 'invited'
} as const;

export type CompanyUserDtoAccountStatusEnum = typeof CompanyUserDtoAccountStatusEnum[keyof typeof CompanyUserDtoAccountStatusEnum];

/**
 * 
 * @export
 * @interface CompanyUserWithRolesDto
 */
export interface CompanyUserWithRolesDto {
    /**
     * The user\'s unique identifier.
     * @type {string}
     * @memberof CompanyUserWithRolesDto
     */
    'id': string;
    /**
     * The user\'s email.
     * @type {string}
     * @memberof CompanyUserWithRolesDto
     */
    'email': string;
    /**
     * The user\'s first name.
     * @type {string}
     * @memberof CompanyUserWithRolesDto
     */
    'firstName': string;
    /**
     * The user\'s last name.
     * @type {string}
     * @memberof CompanyUserWithRolesDto
     */
    'lastName': string;
    /**
     * The user\'s phone number.
     * @type {string}
     * @memberof CompanyUserWithRolesDto
     */
    'phone'?: string | null;
    /**
     * The user\'s preferred language code.
     * @type {string}
     * @memberof CompanyUserWithRolesDto
     */
    'preferredLanguageCode': string;
    /**
     * The ID that the user\'s identity provider uses.
     * @type {string}
     * @memberof CompanyUserWithRolesDto
     */
    'identityProviderId': string;
    /**
     * The user is verified or not.
     * @type {boolean}
     * @memberof CompanyUserWithRolesDto
     */
    'emailVerified': boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserWithRolesDto
     */
    'accountStatus': CompanyUserWithRolesDtoAccountStatusEnum;
    /**
     * Datetime when account was created
     * @type {string}
     * @memberof CompanyUserWithRolesDto
     */
    'createAt': string;
    /**
     * 
     * @type {CompanyUserWithRolesDtoRoles}
     * @memberof CompanyUserWithRolesDto
     */
    'roles': CompanyUserWithRolesDtoRoles;
}

export const CompanyUserWithRolesDtoAccountStatusEnum = {
    Pending: 'pending',
    Active: 'active',
    Declined: 'declined',
    Invited: 'invited'
} as const;

export type CompanyUserWithRolesDtoAccountStatusEnum = typeof CompanyUserWithRolesDtoAccountStatusEnum[keyof typeof CompanyUserWithRolesDtoAccountStatusEnum];

/**
 * 
 * @export
 * @interface CompanyUserWithRolesDtoRoles
 */
export interface CompanyUserWithRolesDtoRoles {
    /**
     * 
     * @type {Array<Role>}
     * @memberof CompanyUserWithRolesDtoRoles
     */
    'assigned'?: Array<Role>;
    /**
     * 
     * @type {Array<Role>}
     * @memberof CompanyUserWithRolesDtoRoles
     */
    'default'?: Array<Role>;
}
/**
 * 
 * @export
 * @interface CompanyWithAddressDto
 */
export interface CompanyWithAddressDto {
    /**
     * The company\'s unique identifier.
     * @type {string}
     * @memberof CompanyWithAddressDto
     */
    'id': string;
    /**
     * The company\'s name.
     * @type {string}
     * @memberof CompanyWithAddressDto
     */
    'name': string;
    /**
     * The company\'s business relation id.
     * @type {string}
     * @memberof CompanyWithAddressDto
     */
    'businessRelationId'?: string | null;
    /**
     * The company\'s business relation type.
     * @type {string}
     * @memberof CompanyWithAddressDto
     */
    'businessRelationType'?: CompanyWithAddressDtoBusinessRelationTypeEnum | null;
    /**
     * The company\'s favorite dealer business relation id.
     * @type {string}
     * @memberof CompanyWithAddressDto
     */
    'dealerBusinessRelationId'?: string | null;
    /**
     * 
     * @type {CompanyWithAddressDtoAddress}
     * @memberof CompanyWithAddressDto
     */
    'address'?: CompanyWithAddressDtoAddress | null;
}

export const CompanyWithAddressDtoBusinessRelationTypeEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type CompanyWithAddressDtoBusinessRelationTypeEnum = typeof CompanyWithAddressDtoBusinessRelationTypeEnum[keyof typeof CompanyWithAddressDtoBusinessRelationTypeEnum];

/**
 * The primary address of the company.
 * @export
 * @interface CompanyWithAddressDtoAddress
 */
export interface CompanyWithAddressDtoAddress {
    /**
     * The company address city.
     * @type {string}
     * @memberof CompanyWithAddressDtoAddress
     */
    'city': string;
    /**
     * The company address country code.
     * @type {string}
     * @memberof CompanyWithAddressDtoAddress
     */
    'countryCode': string;
    /**
     * The company address street.
     * @type {string}
     * @memberof CompanyWithAddressDtoAddress
     */
    'street': string;
    /**
     * The company address zip code.
     * @type {string}
     * @memberof CompanyWithAddressDtoAddress
     */
    'zipCode': string;
}
/**
 * 
 * @export
 * @interface CreateCompanyAddressDto
 */
export interface CreateCompanyAddressDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyAddressDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyAddressDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyAddressDto
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyAddressDto
     */
    'zipCode': string;
}
/**
 * 
 * @export
 * @interface CreateMachineDto
 */
export interface CreateMachineDto {
    /**
     * The serial number of the machine
     * @type {string}
     * @memberof CreateMachineDto
     */
    'serialNumber': string;
    /**
     * The custom name assigned by the user (optional)
     * @type {string}
     * @memberof CreateMachineDto
     */
    'customMachineName': string | null;
}
/**
 * 
 * @export
 * @interface CreateUserFacingCompanyDto
 */
export interface CreateUserFacingCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserFacingCompanyDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserFacingCompanyDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserFacingCompanyDto
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserFacingCompanyDto
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserFacingCompanyDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface GetAllCompanyUsersResponseDto
 */
export interface GetAllCompanyUsersResponseDto {
    /**
     * 
     * @type {Array<CompanyUserDto>}
     * @memberof GetAllCompanyUsersResponseDto
     */
    'data': Array<CompanyUserDto>;
}
/**
 * 
 * @export
 * @interface GetCompanyUserResponseDto
 */
export interface GetCompanyUserResponseDto {
    /**
     * 
     * @type {CompanyUserWithRolesDto}
     * @memberof GetCompanyUserResponseDto
     */
    'data': CompanyUserWithRolesDto;
}
/**
 * 
 * @export
 * @interface InviteEmployeeRequestDto
 */
export interface InviteEmployeeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InviteEmployeeRequestDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InviteEmployeeRequestDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof InviteEmployeeRequestDto
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface InviteEmployeeResponseDto
 */
export interface InviteEmployeeResponseDto {
    /**
     * 
     * @type {InviteEmployeeResponseDtoData}
     * @memberof InviteEmployeeResponseDto
     */
    'data': InviteEmployeeResponseDtoData;
}
/**
 * 
 * @export
 * @interface InviteEmployeeResponseDtoData
 */
export interface InviteEmployeeResponseDtoData {
    /**
     * 
     * @type {string}
     * @memberof InviteEmployeeResponseDtoData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteEmployeeResponseDtoData
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface MachineDto
 */
export interface MachineDto {
    /**
     * The serial number of the machine
     * @type {string}
     * @memberof MachineDto
     */
    'serialNumber': string;
    /**
     * The model of the machine
     * @type {string}
     * @memberof MachineDto
     */
    'model': string;
    /**
     * The year of construction of the machine
     * @type {number}
     * @memberof MachineDto
     */
    'yearOfConstruction': number;
    /**
     * Whether the machine has a gsc box or not
     * @type {boolean}
     * @memberof MachineDto
     */
    'hasGsc': boolean;
    /**
     * The custom name assigned by the user (optional)
     * @type {string}
     * @memberof MachineDto
     */
    'customMachineName': string | null;
    /**
     * The current status of the machine
     * @type {string}
     * @memberof MachineDto
     */
    'status': MachineDtoStatusEnum;
    /**
     * The date when the machine was added
     * @type {string}
     * @memberof MachineDto
     */
    'startedAt': string;
    /**
     * The date when the machine was removed from the account or declined
     * @type {string}
     * @memberof MachineDto
     */
    'endedAt': string | null;
}

export const MachineDtoStatusEnum = {
    Pending: 'pending',
    Active: 'active',
    Inactive: 'inactive',
    Declined: 'declined'
} as const;

export type MachineDtoStatusEnum = typeof MachineDtoStatusEnum[keyof typeof MachineDtoStatusEnum];

/**
 * 
 * @export
 * @interface PatchUserDto
 */
export interface PatchUserDto {
    /**
     * Optional first name of the user to update
     * @type {string}
     * @memberof PatchUserDto
     */
    'firstName'?: string;
    /**
     * Optional last name of the user to update
     * @type {string}
     * @memberof PatchUserDto
     */
    'lastName'?: string;
    /**
     * Optional phone number of the user to update
     * @type {string}
     * @memberof PatchUserDto
     */
    'phone'?: string | null;
    /**
     * Optional preferred language code of the user to update
     * @type {string}
     * @memberof PatchUserDto
     */
    'preferredLanguageCode'?: string;
}
/**
 * 
 * @export
 * @interface PatchUserFacingCompanyDto
 */
export interface PatchUserFacingCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof PatchUserFacingCompanyDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchUserFacingCompanyDto
     */
    'dealerBusinessRelationId'?: string;
    /**
     * 
     * @type {CreateCompanyAddressDto}
     * @memberof PatchUserFacingCompanyDto
     */
    'address'?: CreateCompanyAddressDto;
}
/**
 * 
 * @export
 * @interface PatchUserPrimaryAddressBody
 */
export interface PatchUserPrimaryAddressBody {
    /**
     * 
     * @type {string}
     * @memberof PatchUserPrimaryAddressBody
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof PatchUserPrimaryAddressBody
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof PatchUserPrimaryAddressBody
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof PatchUserPrimaryAddressBody
     */
    'countryCode': string;
}
/**
 * 
 * @export
 * @interface Permission
 */
export interface Permission {
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * The role\'s unique identifier.
     * @type {string}
     * @memberof Role
     */
    'id': string;
    /**
     * The role\'s unique identifier.
     * @type {string}
     * @memberof Role
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateCompanyUserRequestDto
 */
export interface UpdateCompanyUserRequestDto {
    /**
     * Role IDs that need to override the previous ones
     * @type {Array<Role>}
     * @memberof UpdateCompanyUserRequestDto
     */
    'assignedRoles': Array<Role>;
}
/**
 * 
 * @export
 * @interface UpdateMachineDto
 */
export interface UpdateMachineDto {
    /**
     * The custom name assigned by the user (optional)
     * @type {string}
     * @memberof UpdateMachineDto
     */
    'customMachineName': string | null;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * The user\'s unique identifier.
     * @type {string}
     * @memberof UserDto
     */
    'id': string;
    /**
     * The user\'s email.
     * @type {string}
     * @memberof UserDto
     */
    'email': string;
    /**
     * The user\'s first name.
     * @type {string}
     * @memberof UserDto
     */
    'firstName': string;
    /**
     * The user\'s last name.
     * @type {string}
     * @memberof UserDto
     */
    'lastName': string;
    /**
     * The user\'s phone number.
     * @type {string}
     * @memberof UserDto
     */
    'phone'?: string | null;
    /**
     * The user\'s preferred language code.
     * @type {string}
     * @memberof UserDto
     */
    'preferredLanguageCode': string;
    /**
     * The ID that the user\'s identity provider uses.
     * @type {string}
     * @memberof UserDto
     */
    'identityProviderId': string;
    /**
     * The ID of the company the user is associated with.
     * @type {string}
     * @memberof UserDto
     */
    'companyId'?: string | null;
    /**
     * The ID of the company contact the user is associated with.
     * @type {string}
     * @memberof UserDto
     */
    'companyContactId'?: string | null;
    /**
     * The user is verified or not.
     * @type {boolean}
     * @memberof UserDto
     */
    'emailVerified': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'accountStatus': UserDtoAccountStatusEnum;
    /**
     * Datetime when account was created
     * @type {string}
     * @memberof UserDto
     */
    'createAt': string;
    /**
     * 
     * @type {UserDtoCompany}
     * @memberof UserDto
     */
    'company'?: UserDtoCompany | null;
}

export const UserDtoAccountStatusEnum = {
    Pending: 'pending',
    Active: 'active',
    Declined: 'declined',
    Invited: 'invited'
} as const;

export type UserDtoAccountStatusEnum = typeof UserDtoAccountStatusEnum[keyof typeof UserDtoAccountStatusEnum];

/**
 * The company the user is associated with.
 * @export
 * @interface UserDtoCompany
 */
export interface UserDtoCompany {
    /**
     * The company\'s unique identifier.
     * @type {string}
     * @memberof UserDtoCompany
     */
    'id': string;
    /**
     * The company\'s name.
     * @type {string}
     * @memberof UserDtoCompany
     */
    'name': string;
    /**
     * The company\'s business relation id.
     * @type {string}
     * @memberof UserDtoCompany
     */
    'businessRelationId'?: string | null;
    /**
     * The company\'s business relation type.
     * @type {string}
     * @memberof UserDtoCompany
     */
    'businessRelationType'?: UserDtoCompanyBusinessRelationTypeEnum | null;
    /**
     * The company\'s favorite dealer business relation id.
     * @type {string}
     * @memberof UserDtoCompany
     */
    'dealerBusinessRelationId'?: string | null;
    /**
     * 
     * @type {CompanyWithAddressDtoAddress}
     * @memberof UserDtoCompany
     */
    'address'?: CompanyWithAddressDtoAddress | null;
}

export const UserDtoCompanyBusinessRelationTypeEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type UserDtoCompanyBusinessRelationTypeEnum = typeof UserDtoCompanyBusinessRelationTypeEnum[keyof typeof UserDtoCompanyBusinessRelationTypeEnum];

/**
 * 
 * @export
 * @interface UserFacingCompanyAddressDto
 */
export interface UserFacingCompanyAddressDto {
    /**
     * 
     * @type {string}
     * @memberof UserFacingCompanyAddressDto
     */
    'id': string;
    /**
     * The company address city.
     * @type {string}
     * @memberof UserFacingCompanyAddressDto
     */
    'city': string;
    /**
     * The company address country code.
     * @type {string}
     * @memberof UserFacingCompanyAddressDto
     */
    'countryCode': string;
    /**
     * The company address street.
     * @type {string}
     * @memberof UserFacingCompanyAddressDto
     */
    'street': string;
    /**
     * The company address zip code.
     * @type {string}
     * @memberof UserFacingCompanyAddressDto
     */
    'zipCode': string;
    /**
     * Whether the address is primary.
     * @type {boolean}
     * @memberof UserFacingCompanyAddressDto
     */
    'isPrimary': boolean;
}
/**
 * 
 * @export
 * @interface UserFacingCompanyDto
 */
export interface UserFacingCompanyDto {
    /**
     * The company\'s unique identifier.
     * @type {string}
     * @memberof UserFacingCompanyDto
     */
    'id': string;
    /**
     * The company\'s name.
     * @type {string}
     * @memberof UserFacingCompanyDto
     */
    'name': string;
    /**
     * The company\'s business relation id.
     * @type {string}
     * @memberof UserFacingCompanyDto
     */
    'businessRelationId'?: string | null;
    /**
     * The company\'s business relation type.
     * @type {string}
     * @memberof UserFacingCompanyDto
     */
    'businessRelationType'?: UserFacingCompanyDtoBusinessRelationTypeEnum | null;
    /**
     * The company\'s favorite dealer business relation id.
     * @type {string}
     * @memberof UserFacingCompanyDto
     */
    'dealerBusinessRelationId'?: string | null;
    /**
     * The addresses of the company.
     * @type {Array<UserFacingCompanyAddressDto>}
     * @memberof UserFacingCompanyDto
     */
    'addresses'?: Array<UserFacingCompanyAddressDto>;
}

export const UserFacingCompanyDtoBusinessRelationTypeEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type UserFacingCompanyDtoBusinessRelationTypeEnum = typeof UserFacingCompanyDtoBusinessRelationTypeEnum[keyof typeof UserFacingCompanyDtoBusinessRelationTypeEnum];

/**
 * 
 * @export
 * @interface UserPermissionsResponseDto
 */
export interface UserPermissionsResponseDto {
    /**
     * 
     * @type {Array<Permission>}
     * @memberof UserPermissionsResponseDto
     */
    'data': Array<Permission>;
}

/**
 * CurrentUserApi - axios parameter creator
 * @export
 */
export const CurrentUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserControllerDelete: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserControllerGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserControllerGetPermissions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/me/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatchUserDto} patchUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserControllerPatch: async (patchUserDto: PatchUserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchUserDto' is not null or undefined
            assertParamExists('currentUserControllerPatch', 'patchUserDto', patchUserDto)
            const localVarPath = `/api/v1/user/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrentUserApi - functional programming interface
 * @export
 */
export const CurrentUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CurrentUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserControllerDelete(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserControllerDelete(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserApi.currentUserControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserControllerGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserControllerGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserApi.currentUserControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserControllerGetPermissions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPermissionsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserControllerGetPermissions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserApi.currentUserControllerGetPermissions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PatchUserDto} patchUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserControllerPatch(patchUserDto: PatchUserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserControllerPatch(patchUserDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserApi.currentUserControllerPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CurrentUserApi - factory interface
 * @export
 */
export const CurrentUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CurrentUserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserControllerDelete(options?: any): AxiosPromise<void> {
            return localVarFp.currentUserControllerDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserControllerGet(options?: any): AxiosPromise<UserDto> {
            return localVarFp.currentUserControllerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserControllerGetPermissions(options?: any): AxiosPromise<UserPermissionsResponseDto> {
            return localVarFp.currentUserControllerGetPermissions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PatchUserDto} patchUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserControllerPatch(patchUserDto: PatchUserDto, options?: any): AxiosPromise<UserDto> {
            return localVarFp.currentUserControllerPatch(patchUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CurrentUserApi - object-oriented interface
 * @export
 * @class CurrentUserApi
 * @extends {BaseAPI}
 */
export class CurrentUserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public currentUserControllerDelete(options?: RawAxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).currentUserControllerDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public currentUserControllerGet(options?: RawAxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).currentUserControllerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public currentUserControllerGetPermissions(options?: RawAxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).currentUserControllerGetPermissions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PatchUserDto} patchUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public currentUserControllerPatch(patchUserDto: PatchUserDto, options?: RawAxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).currentUserControllerPatch(patchUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CurrentUserCompanyApi - axios parameter creator
 * @export
 */
export const CurrentUserCompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * creates a new company for user and give it first company user role
         * @summary 
         * @param {CreateUserFacingCompanyDto} createUserFacingCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerCreateCompany: async (createUserFacingCompanyDto: CreateUserFacingCompanyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserFacingCompanyDto' is not null or undefined
            assertParamExists('currentUserCompanyControllerCreateCompany', 'createUserFacingCompanyDto', createUserFacingCompanyDto)
            const localVarPath = `/api/v1/user/me/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserFacingCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerGetCompany: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/me/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of roles you can assign to the employees
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerGetRoles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/me/company/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch the user company address.
         * @summary 
         * @param {string} id 
         * @param {PatchUserPrimaryAddressBody} patchUserPrimaryAddressBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerPatchAddress: async (id: string, patchUserPrimaryAddressBody: PatchUserPrimaryAddressBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('currentUserCompanyControllerPatchAddress', 'id', id)
            // verify required parameter 'patchUserPrimaryAddressBody' is not null or undefined
            assertParamExists('currentUserCompanyControllerPatchAddress', 'patchUserPrimaryAddressBody', patchUserPrimaryAddressBody)
            const localVarPath = `/api/v1/user/me/company/addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchUserPrimaryAddressBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates company name, primary address and/or dealer id
         * @summary 
         * @param {PatchUserFacingCompanyDto} patchUserFacingCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerPatchCompany: async (patchUserFacingCompanyDto: PatchUserFacingCompanyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchUserFacingCompanyDto' is not null or undefined
            assertParamExists('currentUserCompanyControllerPatchCompany', 'patchUserFacingCompanyDto', patchUserFacingCompanyDto)
            const localVarPath = `/api/v1/user/me/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchUserFacingCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrentUserCompanyApi - functional programming interface
 * @export
 */
export const CurrentUserCompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CurrentUserCompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * creates a new company for user and give it first company user role
         * @summary 
         * @param {CreateUserFacingCompanyDto} createUserFacingCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyControllerCreateCompany(createUserFacingCompanyDto: CreateUserFacingCompanyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFacingCompanyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyControllerCreateCompany(createUserFacingCompanyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyApi.currentUserCompanyControllerCreateCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyControllerGetCompany(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFacingCompanyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyControllerGetCompany(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyApi.currentUserCompanyControllerGetCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List of roles you can assign to the employees
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyControllerGetRoles(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyRolesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyControllerGetRoles(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyApi.currentUserCompanyControllerGetRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Patch the user company address.
         * @summary 
         * @param {string} id 
         * @param {PatchUserPrimaryAddressBody} patchUserPrimaryAddressBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyControllerPatchAddress(id: string, patchUserPrimaryAddressBody: PatchUserPrimaryAddressBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyControllerPatchAddress(id, patchUserPrimaryAddressBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyApi.currentUserCompanyControllerPatchAddress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates company name, primary address and/or dealer id
         * @summary 
         * @param {PatchUserFacingCompanyDto} patchUserFacingCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyControllerPatchCompany(patchUserFacingCompanyDto: PatchUserFacingCompanyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyControllerPatchCompany(patchUserFacingCompanyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyApi.currentUserCompanyControllerPatchCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CurrentUserCompanyApi - factory interface
 * @export
 */
export const CurrentUserCompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CurrentUserCompanyApiFp(configuration)
    return {
        /**
         * creates a new company for user and give it first company user role
         * @summary 
         * @param {CreateUserFacingCompanyDto} createUserFacingCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerCreateCompany(createUserFacingCompanyDto: CreateUserFacingCompanyDto, options?: any): AxiosPromise<UserFacingCompanyDto> {
            return localVarFp.currentUserCompanyControllerCreateCompany(createUserFacingCompanyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerGetCompany(options?: any): AxiosPromise<UserFacingCompanyDto> {
            return localVarFp.currentUserCompanyControllerGetCompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of roles you can assign to the employees
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerGetRoles(options?: any): AxiosPromise<CompanyRolesResponseDto> {
            return localVarFp.currentUserCompanyControllerGetRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * Patch the user company address.
         * @summary 
         * @param {string} id 
         * @param {PatchUserPrimaryAddressBody} patchUserPrimaryAddressBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerPatchAddress(id: string, patchUserPrimaryAddressBody: PatchUserPrimaryAddressBody, options?: any): AxiosPromise<void> {
            return localVarFp.currentUserCompanyControllerPatchAddress(id, patchUserPrimaryAddressBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates company name, primary address and/or dealer id
         * @summary 
         * @param {PatchUserFacingCompanyDto} patchUserFacingCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerPatchCompany(patchUserFacingCompanyDto: PatchUserFacingCompanyDto, options?: any): AxiosPromise<void> {
            return localVarFp.currentUserCompanyControllerPatchCompany(patchUserFacingCompanyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CurrentUserCompanyApi - object-oriented interface
 * @export
 * @class CurrentUserCompanyApi
 * @extends {BaseAPI}
 */
export class CurrentUserCompanyApi extends BaseAPI {
    /**
     * creates a new company for user and give it first company user role
     * @summary 
     * @param {CreateUserFacingCompanyDto} createUserFacingCompanyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyApi
     */
    public currentUserCompanyControllerCreateCompany(createUserFacingCompanyDto: CreateUserFacingCompanyDto, options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyApiFp(this.configuration).currentUserCompanyControllerCreateCompany(createUserFacingCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyApi
     */
    public currentUserCompanyControllerGetCompany(options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyApiFp(this.configuration).currentUserCompanyControllerGetCompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of roles you can assign to the employees
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyApi
     */
    public currentUserCompanyControllerGetRoles(options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyApiFp(this.configuration).currentUserCompanyControllerGetRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch the user company address.
     * @summary 
     * @param {string} id 
     * @param {PatchUserPrimaryAddressBody} patchUserPrimaryAddressBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyApi
     */
    public currentUserCompanyControllerPatchAddress(id: string, patchUserPrimaryAddressBody: PatchUserPrimaryAddressBody, options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyApiFp(this.configuration).currentUserCompanyControllerPatchAddress(id, patchUserPrimaryAddressBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates company name, primary address and/or dealer id
     * @summary 
     * @param {PatchUserFacingCompanyDto} patchUserFacingCompanyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyApi
     */
    public currentUserCompanyControllerPatchCompany(patchUserFacingCompanyDto: PatchUserFacingCompanyDto, options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyApiFp(this.configuration).currentUserCompanyControllerPatchCompany(patchUserFacingCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CurrentUserCompanyUsersApi - axios parameter creator
 * @export
 */
export const CurrentUserCompanyUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete employee account and send them a confirmation email
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerDeleteEmployeeAccount: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('currentUserCompanyUsersControllerDeleteEmployeeAccount', 'id', id)
            const localVarPath = `/api/v1/user/me/company/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary All employees in the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerFindAllUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/me/company/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch employee account with roles
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerGetOne: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('currentUserCompanyUsersControllerGetOne', 'id', id)
            const localVarPath = `/api/v1/user/me/company/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates unverified account and send them invitation email
         * @param {InviteEmployeeRequestDto} inviteEmployeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerInviteEmployee: async (inviteEmployeeRequestDto: InviteEmployeeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteEmployeeRequestDto' is not null or undefined
            assertParamExists('currentUserCompanyUsersControllerInviteEmployee', 'inviteEmployeeRequestDto', inviteEmployeeRequestDto)
            const localVarPath = `/api/v1/user/me/company/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteEmployeeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch employee account with roles
         * @param {string} id 
         * @param {UpdateCompanyUserRequestDto} updateCompanyUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerUpdateOne: async (id: string, updateCompanyUserRequestDto: UpdateCompanyUserRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('currentUserCompanyUsersControllerUpdateOne', 'id', id)
            // verify required parameter 'updateCompanyUserRequestDto' is not null or undefined
            assertParamExists('currentUserCompanyUsersControllerUpdateOne', 'updateCompanyUserRequestDto', updateCompanyUserRequestDto)
            const localVarPath = `/api/v1/user/me/company/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyUserRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrentUserCompanyUsersApi - functional programming interface
 * @export
 */
export const CurrentUserCompanyUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CurrentUserCompanyUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete employee account and send them a confirmation email
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyUsersControllerDeleteEmployeeAccount(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteEmployeeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyUsersControllerDeleteEmployeeAccount(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyUsersApi.currentUserCompanyUsersControllerDeleteEmployeeAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary All employees in the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyUsersControllerFindAllUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllCompanyUsersResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyUsersControllerFindAllUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyUsersApi.currentUserCompanyUsersControllerFindAllUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetch employee account with roles
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyUsersControllerGetOne(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyUserResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyUsersControllerGetOne(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyUsersApi.currentUserCompanyUsersControllerGetOne']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates unverified account and send them invitation email
         * @param {InviteEmployeeRequestDto} inviteEmployeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyUsersControllerInviteEmployee(inviteEmployeeRequestDto: InviteEmployeeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteEmployeeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyUsersControllerInviteEmployee(inviteEmployeeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyUsersApi.currentUserCompanyUsersControllerInviteEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetch employee account with roles
         * @param {string} id 
         * @param {UpdateCompanyUserRequestDto} updateCompanyUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyUsersControllerUpdateOne(id: string, updateCompanyUserRequestDto: UpdateCompanyUserRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyUserResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyUsersControllerUpdateOne(id, updateCompanyUserRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyUsersApi.currentUserCompanyUsersControllerUpdateOne']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CurrentUserCompanyUsersApi - factory interface
 * @export
 */
export const CurrentUserCompanyUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CurrentUserCompanyUsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete employee account and send them a confirmation email
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerDeleteEmployeeAccount(id: string, options?: any): AxiosPromise<InviteEmployeeResponseDto> {
            return localVarFp.currentUserCompanyUsersControllerDeleteEmployeeAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary All employees in the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerFindAllUsers(options?: any): AxiosPromise<GetAllCompanyUsersResponseDto> {
            return localVarFp.currentUserCompanyUsersControllerFindAllUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch employee account with roles
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerGetOne(id: string, options?: any): AxiosPromise<GetCompanyUserResponseDto> {
            return localVarFp.currentUserCompanyUsersControllerGetOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates unverified account and send them invitation email
         * @param {InviteEmployeeRequestDto} inviteEmployeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerInviteEmployee(inviteEmployeeRequestDto: InviteEmployeeRequestDto, options?: any): AxiosPromise<InviteEmployeeResponseDto> {
            return localVarFp.currentUserCompanyUsersControllerInviteEmployee(inviteEmployeeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch employee account with roles
         * @param {string} id 
         * @param {UpdateCompanyUserRequestDto} updateCompanyUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerUpdateOne(id: string, updateCompanyUserRequestDto: UpdateCompanyUserRequestDto, options?: any): AxiosPromise<GetCompanyUserResponseDto> {
            return localVarFp.currentUserCompanyUsersControllerUpdateOne(id, updateCompanyUserRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CurrentUserCompanyUsersApi - object-oriented interface
 * @export
 * @class CurrentUserCompanyUsersApi
 * @extends {BaseAPI}
 */
export class CurrentUserCompanyUsersApi extends BaseAPI {
    /**
     * 
     * @summary Delete employee account and send them a confirmation email
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyUsersApi
     */
    public currentUserCompanyUsersControllerDeleteEmployeeAccount(id: string, options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyUsersApiFp(this.configuration).currentUserCompanyUsersControllerDeleteEmployeeAccount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary All employees in the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyUsersApi
     */
    public currentUserCompanyUsersControllerFindAllUsers(options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyUsersApiFp(this.configuration).currentUserCompanyUsersControllerFindAllUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch employee account with roles
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyUsersApi
     */
    public currentUserCompanyUsersControllerGetOne(id: string, options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyUsersApiFp(this.configuration).currentUserCompanyUsersControllerGetOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates unverified account and send them invitation email
     * @param {InviteEmployeeRequestDto} inviteEmployeeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyUsersApi
     */
    public currentUserCompanyUsersControllerInviteEmployee(inviteEmployeeRequestDto: InviteEmployeeRequestDto, options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyUsersApiFp(this.configuration).currentUserCompanyUsersControllerInviteEmployee(inviteEmployeeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch employee account with roles
     * @param {string} id 
     * @param {UpdateCompanyUserRequestDto} updateCompanyUserRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyUsersApi
     */
    public currentUserCompanyUsersControllerUpdateOne(id: string, updateCompanyUserRequestDto: UpdateCompanyUserRequestDto, options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyUsersApiFp(this.configuration).currentUserCompanyUsersControllerUpdateOne(id, updateCompanyUserRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CurrentUserMachinesApi - axios parameter creator
 * @export
 */
export const CurrentUserMachinesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes a machine from the company. This call does not     delete the machine from the list of machines because it only deactivates     the machine. The machine will still be visible in the list.
         * @summary Deletes a machine
         * @param {string} serialNumber The serial number of the machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerDeleteMachine: async (serialNumber: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            assertParamExists('currentUserMachinesControllerDeleteMachine', 'serialNumber', serialNumber)
            const localVarPath = `/api/v1/user/me/machines/{serialNumber}`
                .replace(`{${"serialNumber"}}`, encodeURIComponent(String(serialNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all machines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerGetAllMachines: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/me/machines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a single machine
         * @param {string} serialNumber The serial number of the machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerGetMachine: async (serialNumber: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            assertParamExists('currentUserMachinesControllerGetMachine', 'serialNumber', serialNumber)
            const localVarPath = `/api/v1/user/me/machines/{serialNumber}`
                .replace(`{${"serialNumber"}}`, encodeURIComponent(String(serialNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a machine
         * @param {string} serialNumber The serial number of the machine
         * @param {UpdateMachineDto} updateMachineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerPatchMachine: async (serialNumber: string, updateMachineDto: UpdateMachineDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            assertParamExists('currentUserMachinesControllerPatchMachine', 'serialNumber', serialNumber)
            // verify required parameter 'updateMachineDto' is not null or undefined
            assertParamExists('currentUserMachinesControllerPatchMachine', 'updateMachineDto', updateMachineDto)
            const localVarPath = `/api/v1/user/me/machines/{serialNumber}`
                .replace(`{${"serialNumber"}}`, encodeURIComponent(String(serialNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMachineDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The machine is created in a pending state and needs to get verified before being active.
         * @summary Creates a new machine
         * @param {CreateMachineDto} createMachineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerPostMachine: async (createMachineDto: CreateMachineDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMachineDto' is not null or undefined
            assertParamExists('currentUserMachinesControllerPostMachine', 'createMachineDto', createMachineDto)
            const localVarPath = `/api/v1/user/me/machines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMachineDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrentUserMachinesApi - functional programming interface
 * @export
 */
export const CurrentUserMachinesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CurrentUserMachinesApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes a machine from the company. This call does not     delete the machine from the list of machines because it only deactivates     the machine. The machine will still be visible in the list.
         * @summary Deletes a machine
         * @param {string} serialNumber The serial number of the machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachinesControllerDeleteMachine(serialNumber: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MachineDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachinesControllerDeleteMachine(serialNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesApi.currentUserMachinesControllerDeleteMachine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns all machines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachinesControllerGetAllMachines(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MachineDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachinesControllerGetAllMachines(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesApi.currentUserMachinesControllerGetAllMachines']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a single machine
         * @param {string} serialNumber The serial number of the machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachinesControllerGetMachine(serialNumber: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MachineDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachinesControllerGetMachine(serialNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesApi.currentUserMachinesControllerGetMachine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a machine
         * @param {string} serialNumber The serial number of the machine
         * @param {UpdateMachineDto} updateMachineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachinesControllerPatchMachine(serialNumber: string, updateMachineDto: UpdateMachineDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MachineDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachinesControllerPatchMachine(serialNumber, updateMachineDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesApi.currentUserMachinesControllerPatchMachine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The machine is created in a pending state and needs to get verified before being active.
         * @summary Creates a new machine
         * @param {CreateMachineDto} createMachineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachinesControllerPostMachine(createMachineDto: CreateMachineDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MachineDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachinesControllerPostMachine(createMachineDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesApi.currentUserMachinesControllerPostMachine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CurrentUserMachinesApi - factory interface
 * @export
 */
export const CurrentUserMachinesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CurrentUserMachinesApiFp(configuration)
    return {
        /**
         * Deletes a machine from the company. This call does not     delete the machine from the list of machines because it only deactivates     the machine. The machine will still be visible in the list.
         * @summary Deletes a machine
         * @param {string} serialNumber The serial number of the machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerDeleteMachine(serialNumber: string, options?: any): AxiosPromise<MachineDto> {
            return localVarFp.currentUserMachinesControllerDeleteMachine(serialNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all machines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerGetAllMachines(options?: any): AxiosPromise<Array<MachineDto>> {
            return localVarFp.currentUserMachinesControllerGetAllMachines(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a single machine
         * @param {string} serialNumber The serial number of the machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerGetMachine(serialNumber: string, options?: any): AxiosPromise<MachineDto> {
            return localVarFp.currentUserMachinesControllerGetMachine(serialNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a machine
         * @param {string} serialNumber The serial number of the machine
         * @param {UpdateMachineDto} updateMachineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerPatchMachine(serialNumber: string, updateMachineDto: UpdateMachineDto, options?: any): AxiosPromise<MachineDto> {
            return localVarFp.currentUserMachinesControllerPatchMachine(serialNumber, updateMachineDto, options).then((request) => request(axios, basePath));
        },
        /**
         * The machine is created in a pending state and needs to get verified before being active.
         * @summary Creates a new machine
         * @param {CreateMachineDto} createMachineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerPostMachine(createMachineDto: CreateMachineDto, options?: any): AxiosPromise<MachineDto> {
            return localVarFp.currentUserMachinesControllerPostMachine(createMachineDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CurrentUserMachinesApi - object-oriented interface
 * @export
 * @class CurrentUserMachinesApi
 * @extends {BaseAPI}
 */
export class CurrentUserMachinesApi extends BaseAPI {
    /**
     * Deletes a machine from the company. This call does not     delete the machine from the list of machines because it only deactivates     the machine. The machine will still be visible in the list.
     * @summary Deletes a machine
     * @param {string} serialNumber The serial number of the machine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesApi
     */
    public currentUserMachinesControllerDeleteMachine(serialNumber: string, options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesApiFp(this.configuration).currentUserMachinesControllerDeleteMachine(serialNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all machines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesApi
     */
    public currentUserMachinesControllerGetAllMachines(options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesApiFp(this.configuration).currentUserMachinesControllerGetAllMachines(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a single machine
     * @param {string} serialNumber The serial number of the machine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesApi
     */
    public currentUserMachinesControllerGetMachine(serialNumber: string, options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesApiFp(this.configuration).currentUserMachinesControllerGetMachine(serialNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a machine
     * @param {string} serialNumber The serial number of the machine
     * @param {UpdateMachineDto} updateMachineDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesApi
     */
    public currentUserMachinesControllerPatchMachine(serialNumber: string, updateMachineDto: UpdateMachineDto, options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesApiFp(this.configuration).currentUserMachinesControllerPatchMachine(serialNumber, updateMachineDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The machine is created in a pending state and needs to get verified before being active.
     * @summary Creates a new machine
     * @param {CreateMachineDto} createMachineDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesApi
     */
    public currentUserMachinesControllerPostMachine(createMachineDto: CreateMachineDto, options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesApiFp(this.configuration).currentUserMachinesControllerPostMachine(createMachineDto, options).then((request) => request(this.axios, this.basePath));
    }
}



