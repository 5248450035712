import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { TelemetryOnlineStatus } from '../../../utils/ApiQueryTypes';
import { StyledCheckFilled, StyledCrossFilled } from './styles';

interface TelemetryStatusIconProps {
  status?: TelemetryOnlineStatus;
}

export const TelemetryStatusIcon = (props: TelemetryStatusIconProps) => {
  const { status } = props;
  switch (status) {
    case TelemetryOnlineStatus.ONLINE:
      return <StyledCheckFilled icon={faCircleCheck} />;
    case TelemetryOnlineStatus.ERROR:
    case TelemetryOnlineStatus.OFFLINE:
    default:
      return <StyledCrossFilled icon={faCircleXmark} />;
  }
};
