import {
  AppConfigurationClient,
  ConfigurationSetting,
  FeatureFlagValue,
  parseFeatureFlag,
} from '@azure/app-configuration';
import { useEffect, useMemo, useState } from 'react';
import { environment } from '../../../environments/environment.prod';

interface AudienceParameters {
  type: 'audience';
  Audience: {
    DefaultRolloutPercentage: number;
    Users: string[];
    Groups: {
      name?: string;
      DefaultRolloutPercentage?: string;
    };
  };
}
const azureClient = new AppConfigurationClient(
  `Endpoint=${environment.azureAppConfigEndpoint}`,
);

/**
 * Retrieves the specified feature flag from Azure App Configuration.
 * @param {string} flagKey App Config Feature Flag key
 * @returns the feature flag for the specified key
 */

const fetchFeatureFlag = async (
  flagKey: string,
): Promise<ConfigurationSetting<FeatureFlagValue> | undefined> => {
  try {
    const result = await azureClient.getConfigurationSetting({
      key: `.appconfig.featureflag/${flagKey.toString().trim()}`,
    });
    return parseFeatureFlag(result);
  } catch (error) {
    console.error(error);
  }
};
const applyTargetFilters = (
  azureFilter: FeatureFlagValue['conditions']['clientFilters'][number],
  flagFilters: object,
): boolean => {
  let filtersMatch = true;
  for (const [key, value] of Object.entries(flagFilters)) {
    if (key === 'email') {
      filtersMatch = !!(
        azureFilter?.parameters as AudienceParameters | undefined
      )?.Audience?.Users?.includes(value);
    }
  }
  return filtersMatch;
};
const evaluateFilters = (
  azureFilters: FeatureFlagValue['conditions']['clientFilters'],
  flagFilters: object,
  flagEnabled: boolean,
): boolean => {
  return azureFilters.every((filter) => {
    switch (filter.name) {
      case 'Microsoft.Targeting':
        return flagEnabled && applyTargetFilters(filter, flagFilters);
      default:
        return null;
      // cases for all other type of azure filter conditions can go here
    }
  });
};
const useFeatureFlag = (
  flagKey: string,
  flagFilters?: object,
): boolean | undefined => {
  const [featureFlag, setFeatureFlag] = useState<
    ConfigurationSetting<FeatureFlagValue> | undefined
  >(undefined);
  useEffect(() => {
    fetchFeatureFlag(flagKey).then((flag) => {
      setFeatureFlag(flag);
    });
  }, [flagKey]);

  //TODO: We need to add a loading state here as well;
  const enabled = useMemo(() => {
    if (!featureFlag) {
      return undefined;
    }

    if (flagFilters) {
      const azureFilters = featureFlag.value.conditions.clientFilters;
      return evaluateFilters(
        azureFilters,
        flagFilters,
        featureFlag.value.enabled,
      );
    }

    return featureFlag.value.enabled;
  }, [featureFlag, flagFilters]);
  return enabled;
};

//TODO: Remove this function and use useFeatureFlag instead, but keep the loading state;
// Keep in mind that all feature flags functions has to be changed as well (in the entire project);
export const useFeatureFlagWithLoading = (
  flagKey: string,
  flagFilters?: object,
) => {
  const [featureFlag, setFeatureFlag] = useState<
    ConfigurationSetting<FeatureFlagValue> | undefined
  >(undefined);
  const [isFlagLoading, setIsFlagLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchFeatureFlag(flagKey).then((flag) => {
      setFeatureFlag(flag);
      setIsFlagLoading(false);
    });
  }, [flagKey]);

  const enabled = useMemo(() => {
    if (!featureFlag) {
      return undefined;
    }

    if (flagFilters) {
      const azureFilters = featureFlag.value.conditions.clientFilters;
      return evaluateFilters(
        azureFilters,
        flagFilters,
        featureFlag.value.enabled,
      );
    }

    return featureFlag.value.enabled;
  }, [featureFlag, flagFilters]);
  return { enabled, isFlagLoading };
};

export { useFeatureFlag };
