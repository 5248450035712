import * as React from 'react';
import useIsMountedRef from 'use-is-mounted-ref';

export const WaitableLink = (props: {
  className?: string;
  children: React.ReactNode;
  onClick: React.MouseEventHandler;
  style?: React.CSSProperties;
  to?: string;
  waitTime?: number;
}) => {
  const { children, onClick, style, to, waitTime, ...rest } = props;
  const [cursor, setCursor] = React.useState('pointer');
  const isMountedRef = useIsMountedRef();
  return (
    <div
      style={{ ...style, cursor }}
      onClick={(event) => {
        isMountedRef.current && setCursor('wait');
        onClick(event);
        /**
         * onClick might have track calls
         * which gets triggered quickly but might take time to get success
         */
        setTimeout(() => {
          if (props.to) {
            window.location.assign(to);
          }
          isMountedRef.current && setCursor('pointer');
        }, waitTime || 1000);
      }}
      {...rest}
    >
      {children}
    </div>
  );
};
