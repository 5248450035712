import { NotificationMessage, notificationsActions } from '@grimme/components';
import { AllInOneProfileData, FakePermission } from '@mygrimme/types';
import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { PostAccount } from '../pages/admin/utils/utils';
import { createAccount, deleteAccount, updateAccount } from '../utils/ApiQuery';
import { loadingStatus } from './utils';

export interface AccountsState {
  entity?: AllInOneProfileData;
  error?: string;
  addStatus: loadingStatus;
  deleteStatus: loadingStatus;
  editStatus: loadingStatus;
}

export const ACCOUNTS_FEATURE_KEY = 'accounts';

export const initialState: AccountsState = {
  entity: undefined,
  error: undefined,
  addStatus: loadingStatus.NOT_LOADED,
  deleteStatus: loadingStatus.NOT_LOADED,
  editStatus: loadingStatus.NOT_LOADED,
};

export const createUserAccount = createAsyncThunk(
  'accounts/create',
  async (
    args: { body: PostAccount; language: string; accessToken: string },
    { dispatch },
  ) => {
    try {
      const response = await createAccount(
        args.body,
        args.language,
        args.accessToken,
      );
      dispatch(
        notificationsActions.addSuccessNotification({
          // TODO: Component library should not enforce what keys can be used
          message: 'myGRIMME_core_create_user_success' as NotificationMessage,
        }),
      );
      return response;
    } catch (error) {
      dispatch(
        notificationsActions.addErrorNotification({
          // TODO: Component library should not enforce what keys can be used
          message: 'myGRIMME_core_create_user_failure' as NotificationMessage,
        }),
      );
    }
  },
);

export const deleteUserAccount = createAsyncThunk(
  'accounts/delete',
  async (args: { email: string; accessToken: string }, { dispatch }) => {
    try {
      const response = await deleteAccount(args.email, args.accessToken);
      dispatch(
        notificationsActions.addSuccessNotification({
          // TODO: Component library should not enforce what keys can be used
          message: 'myGRIMME_core_delete_user_success' as NotificationMessage,
        }),
      );
      return response;
    } catch (error) {
      dispatch(
        notificationsActions.addErrorNotification({
          // TODO: Component library should not enforce what keys can be used
          message: 'myGRIMME_core_delete_user_failure' as NotificationMessage,
        }),
      );
    }
  },
);

export const editAccountData = createAsyncThunk(
  'accounts/get',
  async (
    args: {
      email: string;
      language: string;
      permissions: FakePermission[];
      accessToken: string;
    },
    { dispatch },
  ) => {
    try {
      const response = await updateAccount(
        args.email,
        args.permissions,
        args.language,
        args.accessToken,
      );
      dispatch(
        notificationsActions.addSuccessNotification({
          // TODO: Component library should not enforce what keys can be used
          message: 'myGRIMME_core_edit_user_success' as NotificationMessage,
        }),
      );
      return response;
    } catch (error) {
      dispatch(
        notificationsActions.addErrorNotification({
          // TODO: Component library should not enforce what keys can be used
          message: 'myGRIMME_core_edit_user_failure' as NotificationMessage,
        }),
      );
    }
  },
);

export const accountsSlice = createSlice({
  initialState,
  name: ACCOUNTS_FEATURE_KEY,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(editAccountData.pending, (state: AccountsState) => {
        state.editStatus = loadingStatus.LOADING;
      })
      .addCase(
        editAccountData.fulfilled,
        (
          state: AccountsState,
          action: PayloadAction<AllInOneProfileData | undefined>,
        ) => {
          state.entity = action.payload;
          state.error = undefined;
          state.editStatus = loadingStatus.LOADED;
        },
      )
      .addCase(editAccountData.rejected, (state: AccountsState, action) => {
        state.error = action.error.message;
        state.editStatus = loadingStatus.ERROR;
      })
      .addCase(deleteUserAccount.pending, (state: AccountsState) => {
        state.deleteStatus = loadingStatus.LOADING;
      })
      .addCase(deleteUserAccount.fulfilled, (state: AccountsState) => {
        state.entity = undefined;
        state.error = undefined;
        state.deleteStatus = loadingStatus.LOADED;
      })
      .addCase(deleteUserAccount.rejected, (state: AccountsState, action) => {
        state.error = action.error.message;
        state.deleteStatus = loadingStatus.ERROR;
      })
      .addCase(createUserAccount.pending, (state: AccountsState) => {
        state.addStatus = loadingStatus.LOADING;
      })
      .addCase(createUserAccount.fulfilled, (state: AccountsState) => {
        state.error = undefined;
        state.addStatus = loadingStatus.LOADED;
      })
      .addCase(createUserAccount.rejected, (state: AccountsState, action) => {
        state.error = action.error.message;
        state.addStatus = loadingStatus.ERROR;
      });
  },
});

export const accountsReducer = accountsSlice.reducer;

export const accountsActions = accountsSlice.actions;

export const getAccountsState = (rootState: {
  [ACCOUNTS_FEATURE_KEY]: AccountsState;
}): AccountsState => rootState[ACCOUNTS_FEATURE_KEY];

export const selectAccountsEntity = createSelector(
  getAccountsState,
  (state) => (state?.entity || {}) as AllInOneProfileData,
);

export const isAccountUpdating = createSelector(
  getAccountsState,
  (state) => state.editStatus === loadingStatus.LOADING,
);

export const isAccountUpdated = createSelector(
  getAccountsState,
  (state) => state.editStatus === loadingStatus.LOADED,
);

export const isAccountUpdatingFailed = createSelector(
  getAccountsState,
  (state) => state.editStatus === loadingStatus.ERROR,
);

export const isDeletingAccount = createSelector(
  getAccountsState,
  (state) => state.deleteStatus === loadingStatus.LOADING,
);

export const isAccountDeleted = createSelector(
  getAccountsState,
  (state) => state.deleteStatus === loadingStatus.LOADED,
);

export const isDeletingAccountFailed = createSelector(
  getAccountsState,
  (state) => state.deleteStatus === loadingStatus.ERROR,
);

export const isAccountAdding = createSelector(
  getAccountsState,
  (state) => state.addStatus === loadingStatus.LOADING,
);

export const isAccountAdded = createSelector(
  getAccountsState,
  (state) => state.addStatus === loadingStatus.LOADED,
);

export const isAccountAddingFailed = createSelector(
  getAccountsState,
  (state) => state.addStatus === loadingStatus.ERROR,
);
