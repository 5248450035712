import { withNotificationsReducer } from '@grimme/components';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { accountsReducer, ACCOUNTS_FEATURE_KEY } from './accounts.slice';
import { authReducer, AUTH_FEATURE_KEY } from './auth.slice';
import { dealersReducer, DEALERS_FEATURE_KEY } from './dealers.slice';
import {
  machineConfigurationsReducer,
  MACHINE_CONFIGURATIONS_FEATURE_KEY,
} from './machine-configurations.slice';
import { machinesReducer, MACHINES_FEATURE_KEY } from './machines.slice';
import {
  machinesReducer as machinesReducerV2,
  MACHINES_FEATURE_KEY as MACHINES_FEATURE_KEY_V2,
} from './machines-v2.slice';
import { telemetryReducer, TELEMETRY_FEATURE_KEY } from './telemetry.slice';

const reducers = withNotificationsReducer({
  [AUTH_FEATURE_KEY]: authReducer,
  [DEALERS_FEATURE_KEY]: dealersReducer,
  [MACHINES_FEATURE_KEY]: machinesReducer,
  [MACHINES_FEATURE_KEY_V2]: machinesReducerV2,
  [MACHINE_CONFIGURATIONS_FEATURE_KEY]: machineConfigurationsReducer,
  [TELEMETRY_FEATURE_KEY]: telemetryReducer,
  [ACCOUNTS_FEATURE_KEY]: accountsReducer,
});

const reducer = combineReducers(reducers);

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage: storage,
    whitelist: ['notifications'],
  },
  reducer,
);

const store = configureStore({
  reducer: persistedReducer,
  // Additional middleware can be passed to this array
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== 'production',
  // Optional Redux store enhancers
  enhancers: [],
});

export default store;
export const persistor = persistStore(store);
