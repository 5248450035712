import { MachineMapping } from '@mygrimme/types';
import { TFunction } from 'i18next';
import { TelemetryDataBySerial, TelemetryOnlineStatus } from './ApiQueryTypes';

export const getSortByTelemetryComparator =
  (telemetryData?: TelemetryDataBySerial) =>
  (machine1: MachineMapping, machine2: MachineMapping) => {
    const status1 =
      telemetryData?.[machine1.MachineSerialNumber]?.status || 100;
    const status2 =
      telemetryData?.[machine2.MachineSerialNumber]?.status || 100;

    return status1 - status2;
  };

export const getTelemetryStatusTranslation = (
  status: TelemetryOnlineStatus | undefined,
  lastOnlineAt: string | undefined,
  t: TFunction,
) => {
  const isMachineError = status === TelemetryOnlineStatus.ERROR;
  const isMachineOnline = status === TelemetryOnlineStatus.ONLINE;
  const isMachineOffline = status === TelemetryOnlineStatus.OFFLINE;
  const isMachineUndefined = status === undefined;
  const interpolatedDate = { date: lastOnlineAt }; //for offlineSinceText {{date}}

  const errorText = t('myGRIMME_core_telemetry_status_unknown', 'Error');
  const offlineSinceText = t(
    'myGRIMME_core_telemetry_status_offline_text',
    'Offline since {{date}}',
    interpolatedDate,
  );
  const offlineSinceFallback = t(
    'myGRIMME_core_telemetry_status_offline_text_fallback',
    'Offline',
  );
  const offlineText = lastOnlineAt ? offlineSinceText : offlineSinceFallback;
  const onlineText = t('myGRIMME_core_telemetry_status_online', 'Online');
  const unknownText = t('myGRIMME_core_telemetry_status_other', 'Unknown');

  if (isMachineUndefined) {
    return unknownText;
  }

  if (isMachineError) {
    return errorText;
  }

  if (isMachineOffline) {
    return offlineText;
  }
  if (isMachineOnline) {
    return onlineText;
  }
};
