import { IconButton, generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses(`AddButton`, [
  'addIcon',
  'machinesListAddIcon',
]);

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  border: 'solid',
  borderColor: theme.palette.common.white,
  borderRadius: '50%',
  borderWidth: theme.spacing(0.25),
  display: 'grid',
  height: theme.spacing(5),
  marginLeft: theme.spacing(3),
  padding: theme.spacing(1),
  placeContent: 'center',
  width: theme.spacing(5),
  [`& .${classes.addIcon}`]: {
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  [`&.${classes.machinesListAddIcon}`]: {
    marginRight: theme.spacing(2),
  },
}));
