import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';

export const StyledCheckFilled = styled(FontAwesomeIcon)(({ theme }) => ({
  [`&`]: {
    color: theme.palette.success.main,
    // keeping same size for mobile since
    // its not clearly visible otherwise
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
}));

export const StyledCrossFilled = styled(FontAwesomeIcon)(({ theme }) => ({
  [`&`]: {
    color: theme.palette.grey[300],
    // keeping same size for mobile since
    // its not clearly visible otherwise
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
}));
