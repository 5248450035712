import { Dialog } from '@grimme/components';
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledImage, classes } from './styles';

interface IShopDialogProps {
  handleCancel?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  handleConfirm?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  onClose: (
    event: Record<string, unknown>,
    reason: 'backdropClick' | 'escapeKeyDown' | 'closeButton',
  ) => void;
  open: boolean;
}

export const ShopDialog = (props: IShopDialogProps) => {
  const { handleCancel, handleConfirm, onClose, open } = props;
  const { t } = useTranslation();
  const title = t(
    'myGRIMME_core_open_shop_headline',
    'Bevor es zum Shop weitergeht:',
  );
  const defaultParagraph = `Um die Teileverfügbarkeit beim Händler Deiner Wahl einzusehen und
  eine Bestellung abschließen zu können, musst Du Deinen favorisierten Händler angeben.`;
  const paragraph = t('myGRIMME_core_open_shop_text', defaultParagraph);
  const cancelText = t('myGRIMME_core_later', 'Später');
  const confirmText = t('myGRIMME_core_select', 'Auswählen');
  const icon = (
    <StyledImage
      src="/assets/img/car-stop.svg"
      alt={title}
      className={classes.image}
    />
  );

  return (
    <Dialog
      icon={icon}
      labelCancel={cancelText}
      labelConfirm={confirmText}
      onCancel={handleCancel}
      onClose={onClose}
      onConfirm={handleConfirm}
      open={open}
      title={title}
    >
      <Typography variant="body1" component="p">
        {paragraph}
      </Typography>
    </Dialog>
  );
};
