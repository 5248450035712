import axios from 'axios';
import { useEffect, useState } from 'react';
import { DEFAULT_LOCALE } from '../../app/utils/consts';
import { environment } from '../../environments';
import { useGeolocation } from '../useGeolocation';

export type TContactDetails = {
  email: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  phone: string;
  photo: string;
};

export const useContactDetails = () => {
  const [data, setData] = useState<TContactDetails | null>(null);
  const [dataError, setError] = useState<Error | unknown>(null);

  const geolocationData = useGeolocation();

  useEffect(() => {
    const contact = async () => {
      const apiUrl = `${environment.translationApi}/contact-assistant-details`;
      const params = {
        companyId: 100,
        countryCode: geolocationData.countryCode || DEFAULT_LOCALE,
        languageId: 'de',
        zipCode: geolocationData.zipcode || '00000',
      };
      try {
        const response = await axios.get(apiUrl, {
          params,
        });
        setData(response.data?.query?.salesResponsible);
      } catch (error) {
        setError(error);
      }
    };

    contact();
  }, [geolocationData]);

  return { data, error: dataError };
};
