import { generateUtilityClasses, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const classes = generateUtilityClasses('MachineCard', [
  'cardContent',
  'cardContentWrapper',
  'grid',
  'idicator',
  'machineName',
  'root',
  'telemetryContainer',
  'telemetryStatus',
  'timestamp',
]);

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.grid}`]: {
    color: theme.palette.common.black,
    textDecoration: 'none',
    padding: theme.spacing(1),
  },
  [`& .${classes.cardContent}`]: {
    display: 'flex',
    height: 90,
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      height: 68,
    },
  },
  [`& .${classes.cardContentWrapper}`]: {
    display: 'flex',
    justifyContent: 'center',
  },
  [`& .${classes.idicator}`]: {
    height: 90,
    width: 90,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      height: 66,
      width: 66,
    },
  },
  [`& .${classes.machineName}`]: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing(0.5),
      paddingTop: '0px',
    },
  },
  [`& .${classes.root}`]: {
    boxShadow: 'none',
    transition: theme.transitions.create(['box-shadow', 'transform'], {
      duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      '&:hover': {
        transform: 'translate(0, -5px)',
      },
    },
    width: '100%',
  },
  [`& .${classes.telemetryContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  [`& .${classes.telemetryStatus}`]: {
    color: theme.palette.grey[400],
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginLeft: theme.spacing(0.25),
    },
  },
  [`& .${classes.timestamp}`]: {
    color: theme.palette.grey[400],
    marginLeft: theme.spacing(1),
  },
}));
