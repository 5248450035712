import { environment } from '../../../environments';

export const register = (language: string, hasGridFlag: boolean) => {
  if (!hasGridFlag) {
    return window.location.assign(
      `${environment.registrationLink}/?language=${language}`,
    );
  }

  window.location.assign(`${environment.accountUrl}/${language}/register`);
};

export const getRegisterUrl = (language: string, hasGridFlag: boolean) =>
  hasGridFlag
    ? `${environment.accountUrl}/${language}/register`
    : `${environment.registrationLink}/?language=${language}`;
