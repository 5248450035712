import { AppProps } from 'next/app';
import { SeoHeader, UserCentrics } from '../app/components';
import ProviderWrapper from '../app/providers';
import { removeOldPoliciesFromStorage } from '../app/utils';
import { SentryConsentWrapper } from '../app/utils/sentryConsent';
import '../styles.css';
const LOADING = typeof window === 'undefined';

export default function MyApp({ Component, pageProps, router }: AppProps) {
  return (
    <>
      <UserCentrics locale={pageProps.locale} />
      <div suppressHydrationWarning>
        <SeoHeader {...pageProps} />
        {LOADING ? null : (
          <SentryConsentWrapper>
            <ProviderWrapper pageProps={pageProps}>
              <Component
                {...pageProps}
                locale={pageProps.locale}
                routerPath={router.asPath}
              />
            </ProviderWrapper>
          </SentryConsentWrapper>
        )}
      </div>
    </>
  );
}

removeOldPoliciesFromStorage();
