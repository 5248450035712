import { environment } from '../../environments';

export enum MediaQueries {
  BIG_DESKTOP = '(min-width:1600px)',
  DESKTOP = '(min-width:960px)',
  TABLET = '(max-width:960px)',
  MOBILE = '(max-width:600px)',
}

export const CONTACT_ASSISTANT_NAME = 'GRIMME Service';
export const CONTACT_ASSISTANT_PHONE = '+49 5491 666 666';
export const CONTACT_ASSISTANT_EMAIL = 'mygrimme@grimme.de';
export const CONTACT_ASSISTANT_WHATSAPP = '4916097851151';

export const BASE_FADE_TIMEOUT = 1000;
export const BASE_FADE_MULTIPLIER = 350;

export const CONTACT_ASSISTANT_COOKIE = 'NEXT_WEBSITE_CONTACT_ASSISTANT';

export const DARK_NAVBAR_SCROLL_POSITION = 53;

export const DEBOUNCE_LATENCY = 300;

export const DEFAULT_COUNTRY_CODE = 'DE';

export const DEFAULT_DEALER = {
  Id: 'BR066492',
  Name: 'GRIMME Landmaschinenfabrik GmbH & Co. KG',
  City: 'Damme',
  EMail: 'shop@grimme.de',
  Telefax: '+49 (5491) 666-2298',
  Phone: '+49 (5491) 666-0',
  Website: environment.grimmeWebsiteUrl,
  Street: 'Hunteburger Str. 32',
  ZipCode: '49401',
  CountryId: 'Damme',
  Longitude: 8.201857,
  Latitude: 52.516972,
  ShopParticipant: true,
};

export const DEFAULT_GEOLOCATION = {
  countryName: 'Deutschland',
  countryCode: DEFAULT_COUNTRY_CODE,
  zipcode: '49401',
  countryFlag: '/assets/img/flag_de.svg',
  latitude: '50.93790',
  longitude: '6.87910',
};

export const DEFAULT_HECTARES_UNIT = 'ha';
export const DEFAULT_ENGINE_TIME_UNIT = 'h';

export const DEFAULT_LOCALE = 'de';
export const DEFAULT_MOBILE_PHONE = '+49 5491 666 666';
export const DEFAULT_MACHINE_IMAGE = '/assets/img/default-machine.png';

export const DEFAULT_NOTIFICATION_MESSAGE = {
  myGRIMME_core_machine_add_success:
    'Die Maschine wurde erfolgreich hinzugefügt.',
  myGRIMME_core_machine_add_failure:
    'Die Maschine konnte nicht hinzugefügt werden. Bitte versuche es später erneut.',
  myGRIMME_core_login_success: 'Sie wurden erfolgreich eingeloggt.',
  myGRIMME_core_login_failure:
    'Etwas ging schief. Bitte versuchen Sie es noch einmal.',
  myGRIMME_core_profile_fetch_failure:
    'Beim Laden Deiner Daten ist etwas schief gelaufen. Bitte versuche es später erneut.',
  myGRIMME_core_account_form_pending: 'Deine Daten werden aktualisiert...',
  myGRIMME_core_account_form_success:
    'Deine Daten wurden erfolgreich aktualisiert.',
  myGRIMME_core_account_form_failure:
    'Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.',
  myGRIMME_core_machine_rename_success:
    'Die Maschine würde erfolgreich umbenannt.',
  myGRIMME_core_machine_rename_failure:
    'Es ist ein Fehler aufgetreten. Die Maschine konnte nicht umbenannt werden.',
  myGRIMME_core_machine_delete_success:
    'Die Maschine würde erfolgreich gelöscht.',
  myGRIMME_core_machine_delete_failure:
    'Es ist ein Fehler aufgetreten. Die Maschine konnte nicht gelöscht werden.',
  myGRIMME_core_edit_user_pending: 'Die Daten werden aktualisiert...',
  myGRIMME_core_edit_user_success:
    'Der Benutzer wurde erfolgreich aktualisiert.',
  myGRIMME_core_edit_user_failure:
    'Es ist ein Fehler aufgetreten. Der Benutzer wurde nicht aktualisiert.',
  myGRIMME_core_delete_user_success: 'Der Benutzer wurde erfolgreich gelöscht.',
  myGRIMME_core_delete_user_failure:
    'Es ist ein Fehler aufgetreten. Der Benutzer ist nicht gelöscht worden.',
  myGRIMME_core_create_user_success:
    'Neues Benutzerkonto wurde erfolgreich erstellt.',
  myGRIMME_core_create_user_failure:
    'Es ist ein Fehler aufgetreten. Benutzer kann nicht erstellt werden.',
  myGRIMME_core_cci_error_connecting:
    'Die Verbindung zum Terminal ist fehlgeschlagen. Bitte überprüfe den Session Code und die Internetverbindung des Terminals und versuche es erneut.',
  myGRIMME_core_cci_disconnected:
    'Die Verbindung zum Terminal wurde erfolgreich unterbrochen.',
  myGRIMME_core_auth_loop:
    'Etwas ist schief gelaufen, bitte melden Sie sich erneut an!',
};

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NAME_REGEX = /^([ \u00c0-\u01ffa-zA-Z'\\-])+$/;

export const MACHINE_NAME_CHARACTER_LIMIT = 50;
export const MACHINE_SERIAL_LENGTH = 8;
export const NORMALIZE_LATITUDE = 0.01;

export const FALLBACK_IMAGE = '/assets/img/fallback_image.png';
