import i18n, { i18n as i18nType } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { environment } from '../../environments/environment';

export const LOCAL_STORAGE_LNG_KEY = 'i18nextLng';
export const LANGUAGE_CODE = 'de';

export const getLanguageFromLocalStorage = (): string => {
  if (typeof localStorage === 'undefined') {
    return LANGUAGE_CODE;
  }
  return localStorage.getItem(LOCAL_STORAGE_LNG_KEY) || LANGUAGE_CODE;
};

export const languageExistsAndDifferentFrom = (
  sourceLanguage: string | undefined,
  targetLanguage: string,
): boolean =>
  !!sourceLanguage &&
  sourceLanguage.toLowerCase() !== targetLanguage.toLowerCase();

export async function syncLanguageInI18n(
  lng: string | undefined,
  i18n: i18nType,
) {
  if (lng && i18n.language !== lng) {
    await i18n.changeLanguage(lng);
  }
}
const LocalStorageDetector: Parameters<LanguageDetector['addDetector']>[0] = {
  name: 'localStorage',

  lookup(options) {
    return getLanguageFromLocalStorage();
  },
};

const NavigatorDetector: Parameters<LanguageDetector['addDetector']>[0] = {
  name: 'custom-navigator',

  lookup(options) {
    const found: string[] = [];

    if (typeof navigator !== 'undefined') {
      if (navigator.languages) {
        // chrome only; not an array, so can't use .push.apply instead of iterating
        navigator.languages.forEach((langauge) => found.push(langauge));
      }

      if ((navigator as Navigator & { userLanguage: string }).userLanguage) {
        found.push(
          (navigator as Navigator & { userLanguage: string }).userLanguage,
        );
      }
      if (navigator.language) {
        found.push(navigator.language);
      }
    }

    // hack because return type of lookup is wrong
    // https://github.com/i18next/i18next-browser-languageDetector/pull/223
    return found.length > 0
      ? (found.map((lng) => lng.split('-')[0]) as unknown as string)
      : undefined;
  },
};

const i18nDetectorOptions = {
  order: ['localStorage', 'custom-navigator'],
};

const i18nextBackendOptions = {
  backends: [HttpBackend],
  backendOptions: [
    {
      // prefix for stored languages
      prefix: 'mygrimme_core_translations_',

      // expiration
      expirationTime: 24 * 60 * 60 * 1000,
      loadPath: `${environment.translationApi}/translations?language={{lng}}`,
      addPath: '',
    },
  ],
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(NavigatorDetector);
languageDetector.addDetector(LocalStorageDetector);

i18n.use(languageDetector);
i18n.use(Backend);
// Todo check if this help
i18n.use(initReactI18next);
i18n.init({
  fallbackLng: LANGUAGE_CODE,
  debug: false,
  returnEmptyString: false,
  interpolation: {
    escapeValue: false,
  },

  detection: i18nDetectorOptions,

  backend: i18nextBackendOptions,

  react: {
    useSuspense: false,
  },
});

export default i18n;
